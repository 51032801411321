import request from "@/utils/request";

export const getEvaluateByPage = (data) => {
    return request({
        url: "/openapi/evaluate/getAllByPage",
        data
    })
}

export const createEvaluate = (data) => {
    return request({
        url: "/openapi/evaluate/create",
        data
    })
}
