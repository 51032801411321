import request from "@/utils/request/index"


// 亚马逊支付
export const amazonpay = (data) => {
  return request({
    url: "/openapi/order/amazongpay",
    data
  })
}

export const amazongpayUpdate = (data) => {
  return request({
    url: "/openapi/order/amazongpayUpdate",
    data
  })
}

export const paypalpay = (data) => {
  return request({
    url: "/openapi/order/paypal",
    data
  })
}

export const googlepay = (data) => {
  return request({
    url: "/openapi/order/googlepay",
    data
  })
}

export const cardpay = (data) => {
  return request({
    url: "/openapi/order/cardpay",
    data
  })
}

export const offlinepay = (data) => {
  return request({
    url: "/openapi/order/offlinepay",
    data
  })
}