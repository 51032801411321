// 定义具体的条件类型
import {isNumber, isString, isArray, isEmpty, isDate} from '@/utils/is'
import {unref} from "vue";

export const QueryCondition = {
  EQ: '=', //等于
  BEHIND: '*%', //后模糊
  FRONT: '%*', //前模糊
  ALL: '%*%', //全模糊
  GT: '>*', //字段大于某值
  LT: '*>', //字段小于某值
  IN: 'in*', //多个值
  DESC: 'desc', //降序排序
  ASC: 'asc', //升序排序
  OR:'or'
}


// 包装查询参数
export const queryWrapper = (
  field,
  condition,
  value
) => {
  if (isString(value) || isNumber(value) || isDate(value)) {
    return {
      field,
      reg: condition,
      value: [value]
    }
  } else if (isArray(value)) {
    return {
      field,
      reg: condition,
      value
    }
  } else {
    throw new Error(`查询条件错误，当前查询条件值为：${value}`)
  }
}

// 处理Search组件返回的数据 (对于没有searchSchemas的数据默认条件为EQ)
export const convertQueryParam = (
  param,
  searchSchemas
) => {
  let pageOption = {}
  let paramKeys = Object.keys(unref(param))
  const conditions = []

  // 判断是否存在分页数据
  if (paramKeys.indexOf('page') != -1 && paramKeys.indexOf('pageSize') != -1) {
    pageOption = {
      page: unref(param).page,
      pageSize: unref(param).pageSize
    }
    paramKeys = paramKeys.filter((key) => key !== 'page' && key !== 'pageSize')
  }

  paramKeys.forEach((key) => {
    const paramValue = unref(param)[key]

    // 判断当前条件是否为空数据, 否则直接返回
    if (isEmpty(paramValue)) return

    //查找对应的数据
    const existIndex = searchSchemas.findIndex((searchSchema) => {
      return searchSchema.field === key
    })

    if (existIndex !== -1) {
      const searchSchema = searchSchemas[existIndex]
      if (searchSchema.type === 'date') {
        // 日期类型处理成为两个字段
        conditions.push(queryWrapper(key, QueryCondition.GT, paramValue[0]))
        conditions.push(queryWrapper(key, QueryCondition.LT, paramValue[1]))
      } else {
        if (searchSchema.operate === undefined) {
          throw new Error('请确认查询操作符operate属性正确书写')
        } else {
          conditions.push(queryWrapper(key, searchSchema.operate, paramValue))
        }
      }
    } else {
      conditions.push(queryWrapper(key, QueryCondition.EQ, paramValue))
    }
  })

  return {
    ...pageOption,
    conditions
  }
}
