<template>

  <pc-header></pc-header>
    <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
      <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">{{product.description}}</div>
      <div> 
        <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span >{{product.description}}</span>
      </div>
    </div>
    <div style="width: 1400px;margin: auto;margin-top: 60px;padding-top: 60px;border-radius: 1%;display: flex;justify-content: space-between;">
      <el-card style="width: 500px;border: 0px;" shadow="never" v-if="product">
        <el-image
          style="width: 420px; height: 400px;border-radius: 10px;margin-left: 5px;"
          :src="productImg"
          :preview-src-list="product.imgs.split(',')"
          v-if="product.imgs"
          fit="fill"
        />
        <el-row v-if="product.imgs">
          <el-col :span="4"  v-for="item in product.imgs.split(',')" :key="item">
            <el-link :underline="false">
              <el-image :src="item" style="width: 50px;height: 50px;margin-top: 10px;border: 1px solid #d7d5d5;" @click="chanageImg(item)"></el-image>
            </el-link>
           

            
          </el-col>
        </el-row>
      </el-card>

      <div style="width: 800px;;margin-top: 10px;text-align: left;">
        <div style="font-size: 30px;color: #000;font-weight: 600;margin-bottom: 20px;">
          <span>{{product.description}}</span>
          
        </div>
        <div style="margin-top: 20px;">
          <!-- <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 30px;">Market Price: </el-text> -->
          <el-text style="font-size: 24px;color: #000;font-weight: 600;margin-right: 30px;">$ {{setPrice(product.price)}}</el-text>
          <el-text tag="del" style="margin-right: 30px;color: rgb(204, 102, 102);font-weight: 600;font-size: 20px;">$ {{setPrice(product.oprice)}}</el-text>
          
        </div>

        <div style="margin-top: 20px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">You Save: {{((1-(product.price/product.oprice)) * 100).toFixed(0  )}}%</el-text>
        </div>

        <div style="margin-left: 10px;">
          <template v-for="label in labelList" :key="label.id">
            <el-tag v-if="label.productId === product.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
          </template>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <!-- <el-text style="font-size: 14px;color: #000;margin-right: 55px;">Sold: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.soldNum }}</span> </el-text>
          <el-text style="font-size: 14px;color: #000;margin-right: 55px;">Inventory: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.inventory }}</span></el-text> -->
          <el-text style="font-size: 14px;color: #000;margin-right: 55px;"> <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ setInventoryMsg(product.inventory) }}</span></el-text>
          <el-text style="font-size: 14px;color: #000;margin-right: 55px;" v-if="product.nextCome"> <span  style="font-size: 20px;color: #000;font-weight: 600;">Restock Time: {{ product.nextCome }}</span></el-text>
        </div>

        <div style="margin-top: 50px;display: flex;">
          <div v-if="attr && attr.length > 0" >
            <div v-for="item in attr" :key="item.key" style="margin-top: 20px">
              <div style="font-size: 14px;color: #000;font-weight: 500;margin-bottom: 10px;"> {{item.key}}</div>
              <el-radio-group v-model="chanceSpec[item.key]" size="large" @change="changeSpec(item.key)" >
                <template v-for="value in item.values" :key="value">
                  <el-radio-button :label="value" :value="value" border style="margin-right: 30px;border-radius: 30px;" />
                </template>
                
              </el-radio-group>
            </div>

          </div>

          <div>
            <el-image v-if="product.specImg" :src="product.specImg" style="margin-left: 30px;width: 120px;height: 120px;"></el-image>
          </div>
        </div>
       
       
        <div style="color: #000;margin-bottom: 20px;margin-top: 50px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">Qty: </el-text>
          <el-input-number v-model="productNum" :min="1" :max="product.inventory" :step-strictly="true"  style="height: 50px;font-weight: 600;font-size: 30px;"/>
        </div>
        <div style="font-size: 30px;color: #fff;font-weight: 600;margin-bottom: 20px;margin-top: 20px;width: 380px;">
          

          <el-button round bg type="danger" style="width: 380px;height: 60px;font-size: 18px;font-weight: 600;margin-bottom: 10px;" @click="addCart">
            <el-icon size="30" style="vertical-align: middle;margin-right: 10px;">
              <ShoppingCartFull />
            </el-icon>
            Add to cart
          </el-button>

          <el-button type="success" text bg round style="width: 170px;height: 50px;font-size: 18px;font-weight: 600;" @click="addWishList">Wish List</el-button>
          
          <el-button type="warning" style="width: 170px;margin-left: 20px;font-weight: 600;font-size: 18px;height: 50px;" text bg round @click="addCompare">Compare</el-button>
        </div>
      </div>
    </div>
    
    <div style="margin:100px auto;width:1600px;">
      <el-tabs v-model="activeName" class="demo-tabs" style="font-size: 18px;">
        <el-tab-pane label="Details" name="first">
          <div v-html="product.overview"></div>
        </el-tab-pane>
        <el-tab-pane label="Reviews" name="second" style="text-align: left;">

          <!-- <el-form :model="commentForm" label-width="100px" style="margin-bottom: 30px;width: 1200px;margin-top: 30px;">
            <el-form-item label="评分" style="margin-bottom: 20px;">
              <el-rate v-model="commentForm.rate" size="Large"/>
            </el-form-item>
            <el-form-item label="上传图片" style="margin-bottom: 20px;">
            </el-form-item>
            <el-form-item label="评价" style="margin-bottom: 20px;">
              <el-input v-model="commentForm.comment" type="textarea" autosize size="large"></el-input>
            </el-form-item>
            <el-button type="primary" @click="handleSubmit" style="margin-top: 20px;width: 100px;height: 40px;margin-left: 30vw;">提交评论</el-button>
          </el-form> -->
          <div v-if="artcleList.length > 0">
            <div  v-for="item in artcleList" :key="item" style="padding-bottom: 10px;width: 800px;">
                
                  
                  <div style="margin: 10px 10px;">
                    <el-avatar :size="50" :src="customerImg" style="vertical-align: middle;margin-right: 20px;"/>
                    <!-- <el-text class="mx-1" tag="b" style="font-size: 20px;">{{ item.name }}</el-text> -->
                    <span style="margin-left: 30px;margin-right: 30px;">{{setName(item.firstName +" "+ item.lastName)}}</span>
                    <span style="margin-left: 30px;margin-right: 30px;">{{timeTran(item.createTime)}}</span>
                    <el-rate v-model="item.level" size="large" style="zoom: 1.5;vertical-align: middle;" disabled/>
                  </div>
                  
                  
                  <div style="margin-left: 30px;width: 600px;">
                    <!-- <div style="text-align: center;font-size: 24px;font-weight: 600;">买家评论</div> -->
                    <div >{{item.content}}</div>
                  </div>
                  

                  <div style="margin-top: 30px;margin-left: 20px;"> 
                    <span style="font-weight: 600;margin-right: 30px;">photo: </span> 
                    <template v-if="item.imgs" v-for="img in item.imgs.split(',')" :key="img">
                      <el-image style="width: 50px;height: 50px;margin-right: 20px;vertical-align: middle;border: #5baacd 1 solid;" :preview-src-list="item.imgs.split(',')" :src="img"></el-image>
                    </template>
                  </div>
                
                  <el-divider />
                
                
                
            </div>
            <el-pagination
            v-model:current-page="paginationData.page"
            v-model:page-size="paginationData.pageSize"
            :total="paginationData.total"
            @current-change="getEvaluate"
            background layout="prev, pager, next"
            style="margin-left: 20vw;margin-top: 80px;"/>
          </div>
          <div v-else>
            <el-empty description="No customer reviews"></el-empty>
          </div>
           
              
        </el-tab-pane>
        <el-tab-pane label="More Information" name="third">
          
          <el-table :data="prodcutSpec" style="width: 100%">
            <el-table-column prop="name" label="name" width="180" >
              <template #default="scope">
                <span :style="{ whiteSpace: 'pre-wrap' }">{{ setSpec(scope.row.specDesc) || 'default' }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="weight" label="weight(lb)" width="180" >
              <template #default="scope">
                {{setPrice(scope.row.weight)}}
              </template>
            </el-table-column>
            <el-table-column prop="volume" label="volume(in)" />
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </div>
   
    <!-- 猜你喜欢	-->
	<div style="width: 1400px;margin: 100px auto" v-if="activeName === 'first'">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">You May Also Like</el-text>
		<router-link to="/pages/productList?sort=mostPopular">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 900px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in sortList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;">
								<div style="margin: auto;">
								<el-image style="width: 100%; height: 300px" :src='item.imgs.split(",")[0]' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ setPrice(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
										${{ setPrice(item.oprice) }}
									</el-text>
								</div>
								
							</el-card>
							
						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>  
	</div>

    <!-- 猜你喜欢	-->
	<div style="width: 1400px;margin: 100px auto" v-else-if="activeName === 'second'">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">Frequently bought together</el-text>
		<router-link to="/pages/productList?sort=bestSalers">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 780px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in togetherList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;">
								<div style="margin: auto;">
								<el-image style="width: 100%; height: 300px" :src='item.imgs.split(",")[0]' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ setPrice(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
										${{ setPrice(item.oprice) }}
									</el-text>
								</div>
								
							</el-card>
							
						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>  
	</div>

    <!-- 猜你喜欢	-->
	<div style="width: 1400px;margin: 100px auto" v-else-if="activeName === 'third'">
		<el-text class="mx-1" tag="b" style="font-size: 30px;margin-left: 100px;">Related Products</el-text>
		<router-link to="/pages/productList?sort=newArrivals">
			<el-link class="mx-1" tag="b" style="font-size: 20px;margin-left: 900px;color: rgb(191, 182, 182);">more</el-link>
		</router-link>
		<el-carousel height="450px" style="margin: 30px 0;" arrow="always">
			
			<el-carousel-item v-for="(productList, index) in relateList" :key="index" arrow="always">
				<el-row :gutter="30" style="margin: 0px 50px;">
					
					
					<el-col :span="6" height="420px" v-for="item in productList" :key="item.id">
						<router-link :to="'/pages/productDetail?productId=' + item.id">
							<el-card shadow="hover" style="height: 400px;">
								<div style="margin: auto;">
								<el-image style="width: 100%; height: 300px" :src='item.imgs.split(",")[0]' fit="fill"></el-image>
								</div>
								<div style="text-align: center;font-size: 14px;font-weight: 600;">
									<el-text class="mx-1" size="large" style="margin: auto;">{{ item.description }}</el-text>
								</div>
								<div style="text-align: center;">
									<el-text class="mx-1" size="large">${{ setPrice(item.price) }}</el-text>
									<el-text class="mx-1" size="small" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
										${{ setPrice(item.oprice) }}
									</el-text>
								</div>
								
							</el-card>
							
						</router-link>
					</el-col>
				</el-row>
			</el-carousel-item>
		</el-carousel>  
	</div>

  <pc-footer></pc-footer>
</template>

<script>
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'

import useCartCache from "@/store/cart";
import useCompareCache from "@/store/compare";
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import { ElMessage,ElMessageBox  } from 'element-plus'
import {useRoute, useRouter} from "vue-router";
import {getAgeList, getCategoryList,  getProductLabelAll, getSpuAll,getProductById,getProductAllByPage} from "@/api/spu"
import { getEvaluateByPage} from "@/api/spu/evaluate"
import {getAccessToken} from "@/utils/auth";
import { doAddWishList } from "@/api/spu/wishList.js";
import moment from 'moment-timezone';

export default {
  name: 'productDetail',
  props: {
    msg: String
  },
  data() {
    return {
      
      customerImg: require('@/assets/img/customer.png'),
      product:{ },
      attr:[],
      prodcutSpec:[],
      chanceSpec:{},
      productImg: null,
      productNum:1,
      activeName:"first",
      imgList:[

      ],
      likeList:[],
      sortList:[],
      togetherList:[],
      relateList:[],
      ageOptions:[],
      labelList:[],
      artcleList:[
       
      ],
      
      cartCacheStore : useCartCache(),
      compareCacheStore : useCompareCache(),
      router : useRouter(),
      paginationData:{
        page:1,
        pageSize:10,
        total:0

      }
    }
  },
  watch: {
    '$route'(to, from) {
      if (to.query.productId !== from.query.productId) {
        // 重新获取产品详情数据
        this.getAgeList();
        this.getProduct();
        this.getSort();
      }
    },
  },
  created() {
    this.getAgeList();
    this.getProduct();
    this.getSort();
    this.getEvaluate();
    
  },
  methods: {
    async getAgeList(){
      const {data: list} = await getAgeList({
        
        conditions: []
      })
      this.ageOptions = list;
    
    },
    async getProduct(){
      const productId = this.$route.query?.productId
      const {data:product} = await getProductById({id: productId})
      this.product = product;
      this.productImg = product.imgs.split(',')[0];

      //获取规格
      const {data : prodcutSpec} = await getSpuAll({
        conditions: [queryWrapper("productId", QueryCondition.EQ, product.id),queryWrapper("sort", QueryCondition.DESC, "")]
      });
      this.prodcutSpec = prodcutSpec;
      this.attr = JSON.parse(product.attr);
      
      const specDesc = JSON.parse(prodcutSpec[0].specDesc);
      if(specDesc){
        for(let i = 0; i < specDesc.length; i++){
          const spec = specDesc[i];
          this.chanceSpec[spec.key] = spec.value;
        }
      }
      

      this.product.soldNum = prodcutSpec[0].soldNum;
      this.product.inventory = prodcutSpec[0].inventory;
      this.product.oprice = prodcutSpec[0].oprice;
      this.product.price = prodcutSpec[0].price;
      this.product.nextCome = prodcutSpec[0].nextCome ? moment(prodcutSpec[0].nextCome).tz('America/New_York').format("YYYY-MM-DD") : null;

      //获取标签
      const {data:labels} = await getProductLabelAll({
        conditions: [queryWrapper("productId", QueryCondition.EQ, product.id)]
      });
      this.labelList = labels;
    },
    async getEvaluate(){
      const {data: {list,pageNum,total}} = await getEvaluateByPage({
        page: this.paginationData.page,
        pageSize: this.paginationData.pageSize, 
        conditions: [queryWrapper("productId", QueryCondition.EQ, this.$route.params.productId),queryWrapper("status", QueryCondition.EQ, 2)]
      });
      this.paginationData.page = pageNum;
      this.paginationData.total = total;
      this.artcleList = list;
    },
    setPrice(value){
      return (value/100).toFixed(2);
    },
    async addCart(){
      this.product.num = this.productNum;

      
      let spec ={}
      let array = []
      for (const [key, value] of Object.entries(this.chanceSpec)) {
        const s = {
          key: key,
          value: value
        };
        array.push(s);
      }
      this.prodcutSpec.forEach((value) => {
        
        if(value.specDesc == JSON.stringify(array)){
          
          spec = value;
          return;
        }
      });
      if(!spec.id || spec.inventory < this.productNum){
        ElMessage({
        message: "Out of stock",
        type: "error"
        });
        return;
      }
      
      await this.cartCacheStore.addCartItem({...this.product, productSpecId: spec.id, specDesc: array})
      this.cartCacheStore.cartDrawer = true
      
      ElMessage({
          message: "Successfully added to shopping cart",
          type: "success"
        });
    },
    chanageImg(item){
      this.productImg = item
    },
    addCompare(){
      var compareList = JSON.parse(localStorage.getItem("compareList")) || [{}];
      var flag = true
      for(let i = 0; i < compareList.length; i++){
        if(compareList[i].id === this.product.id){
          flag = false;
          break;
        }
      }
      if(flag){
        compareList.push(this.product);
        localStorage.setItem("compareList", JSON.stringify(compareList));
        ElMessageBox.confirm(
          'Would you like to check the comparison list?',
          'Success!',
          {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'success',
          }
        ).then(() => {
          this.router.push({path: '/pages/compare'})
        })
        .catch(() => {
        
        })
      }else{
        
        ElMessage({
          message: "The product already exists in the comparison list",
          type: "warning"
        });
      }
    },
    changeSpec(){
      let array = []
     
      for (const [key, value] of Object.entries(this.chanceSpec)) {
        const s = {
          key: key,
          value: value
        };
        array.push(s);
      }
      this.product.soldNum = 0;
      this.product.inventory = 0;
      this.product.oprice = 0;
      this.product.price = 0;
      this.product.nextCome = null;
      this.product.specImg = null;
      this.prodcutSpec.forEach((value) => {
        
        if(value.specDesc == JSON.stringify(array)){
          
          this.product.soldNum = value.soldNum;
          this.product.inventory = value.inventory;
          this.product.oprice = value.oprice;
          this.product.price = value.price;
          this.product.nextCome = value.nextCome ?  moment(value.nextCome).tz('America/New_York').format("YYYY-MM-DD") : null;
          this.product.specImg = value.imgs.split(',')[0];
          return;
        }
      });
    },
    getLabelName(id){
      let name = '';
      this.ageOptions.forEach((value) => {
        
        if(value.id === id){
          name = value.name;
        }
      });
      return name;
    },
    async addWishList(){
      
      if(getAccessToken()){
        await doAddWishList({productId: this.product.id});
        ElMessage({
          message: "Successfully added to wish list",
          type: "success"
        });
      }else{
        ElMessageBox.confirm(
          'You are not logged in. Would you like to log in now?',
          'Error',
          {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'error',
          }
        ).then(() => {
          debugger
          this.router.push({path: '/pages/login',query:{
                redirect: this.router.currentRoute.fullPath
              }})
        })
        .catch(() => {
        
        })
      }
    },
    resolveList(array, splitGap = 4) {
			const length = array && array.length > 0 ? array.length : 0;
			const returnArray = [];
			let cacheList = [];
			
			// 如果数组为空直接返回空数组
			if (length === 0) return returnArray;
			
			array.forEach((item, index) => {
				const remainderNum = index % splitGap;
				if (remainderNum === 0 && index !== 0) {
					returnArray.push(cacheList)
					cacheList = []
				}
				cacheList.push(item);
			})
			returnArray.push(cacheList)
			console.log("returnArray",returnArray)
			return returnArray
		},
    async getSort(){
      const productId = this.$route.query?.productId
			const {data: {list: productList}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("sort", QueryCondition.DESC, []),
          queryWrapper("id", QueryCondition.ALL, [productId.slice(-1)])]
			});
			this.sortList = this.resolveList(productList, 4);

      const {data: {list: productList2}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("sold_num", QueryCondition.DESC, []),
          queryWrapper("id", QueryCondition.ALL, [productId.slice(-1)])]
			});
			this.togetherList = this.resolveList(productList2, 4);

      const {data: {list: productList3}} = await getProductAllByPage({
				page: 1,
				pageSize: 12,
				conditions: [queryWrapper("create_time", QueryCondition.DESC, []),
          queryWrapper("id", QueryCondition.ALL, [productId.slice(-1)])]
			});
			this.relateList = this.resolveList(productList3, 4);
			 
		},
    timeTran (time) {
      return moment(time).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
    },
    setSpec(item) {
      if(item){
        const json = JSON.parse(item);
        let str = '';
        json.forEach((value) => {
          str += value.key + ': ' + value.value + '  \n '
        })
        return str;
      }
    },
    setName(item){
      return item[0]+item.replace(/[a-zA-Z0-9]/g, "*")
    },
    setInventoryMsg(inventory){
      if(inventory > 15){
        return 'In stock';
      }else if(inventory == 0){
        return 'Out of stock';
      }else{
        return 'Only '+inventory+' left in stock - order soon.';
      }
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }
   
    .el-radio-button::v-deep .el-radio-button__inner {
      border-radius: 30px !important;
    }
</style>
