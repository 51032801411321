import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import "./style/index.css"
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import router from "@/route/index"
import PcFooter from "@/components/footer.vue"
import PcHeader from "@/components/header.vue"


const app = createApp(App)
const store = createPinia()


// 注册所有的icon组件
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

// 全局注册header footer
app.component("PcFooter", PcFooter)
app.component("PcHeader", PcHeader)

app.use(ElementPlus) // 挂载 ElementPlus
app.use(router) // 挂载 路由
app.use(store) // 挂载store

app.mount('#app')
