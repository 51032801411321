<template>
	
	<pc-header></pc-header>
	<!--	<div>当前查询参数：{{ JSON.stringify(route.query) }}</div>-->
		<div style="text-align: center;height: 100px;background-image: url(../assets/img/productlist.png);">
			<!-- <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Proudct List</div> -->
			
			<!-- <div>
				<el-image style="width: 100%;height: 100px;" fit="cover" :src="titlebg">
				
				</el-image>	
				<el-link >Home</el-link>
				/
				<span style="color: #fff">{{setCateGoryName()}}</span>
				
			</div> -->

			<div style="position: relative;">
				<el-image style="width: 100%; height: 100px;" fit="cover" :src="titlebg"></el-image>

				<!-- 包裹文字元素的定位容器 -->
				<div style="
					position: absolute;
					bottom: 0; /* 控制距离底部的距离，可根据需要调整 */
					left: 0; /* 左对齐，也可改为 right: 0; 以右对齐 */
					width: 100%; /* 适应图片宽度 */
					display: flex;
					justify-content: space-between;
					align-items: center;
					color: #fff; /* 统一设置文字颜色 */
					background: rgba(0, 0, 0, 0.5); /* 可选，添加半透明黑色背景以提高文字可读性 */
					padding: 10px; /* 可根据需要调整内边距 */
					height: 105px
				">
					<div style="margin:auto">
						<el-link style="color: #fff">Home</el-link>
						/
						<span>{{ setCateGoryName() }}</span>
					</div>
					
				</div>
			</div>

		</div>
	
	<el-container style="margin-bottom:80px">
		<el-aside width="22vw"  style="margin: 80px 30px 30px 8vw;max-width: 360px;min-width: 240px;">
			<el-form class="search-container"
			         style="width: 22vw;font-weight: 600;font-size: 16px;border: rgb(149 202 225) solid 1px;border-radius: 10px;padding: 40px 20px;max-width: 360px;min-width: 240px;"
			         label-width="140px" size="large" :model="formData" :label-position="'top'">
				<el-form-item label="Filter by Price">
					<el-slider v-model="currentPrice" range :max="currentMaxPrice" :min="0" style="margin-left: 10px;" col @change="changePrice"/>
					<span style="font-weight: 600;font-size: 16px;margin-left: 60px;">
						<el-input v-model="currentPrice[0]" style="width: 80px;" @blur="changePrice"></el-input> - <el-input v-model="currentPrice[1]" style="width: 80px;" @blur="changePrice"></el-input>
						
					</span>
				</el-form-item>
				
				<el-form-item label="Filter by Category">
					<el-select v-model="formData.category" class="m-2" placeholder="Category" size="large"
					           style="width: 400px;" clearable>
						<el-option
							v-for="item in categoryOptions"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
				
				<el-form-item label="Filter by Age">
					<el-checkbox-group
						v-model="formData.age"
						
						style="width: 400px;zoom: 1.2;"
					>
						<div v-for="age in ageOptions" :key="age.id" style="min-width: 200px;text-align: left;">
							<el-checkbox :label="age.id" size="large" style="margin-bottom: 16px">
								{{ age.name }}
							</el-checkbox>
						</div>
					</el-checkbox-group>
				</el-form-item>
				
				<el-form-item label="Sort by">
					<!-- <el-radio-group v-model="formData.orderPriceSort" size="large" style="margin-left: 10px;">
						<el-radio-button label="DESC"/>
						<el-radio-button label="ASC"/>
					</el-radio-group> -->
					<el-select v-model="formData.sort" class="m-2" placeholder="Category" size="large"
					           style="width: 400px;" clearable>
						<el-option
							v-for="item in sortOptions"
							:key="item.id"
							:label="item.name"
							:value="item.id"
						/>
					</el-select>
				</el-form-item>
				
			</el-form>
			
			<!-- 比较 -->
			<div style="margin-top: 50px;border: #eee solid 1px;border-radius: 10px;">
				<div style="background: #eee;font-size:20px;text-align: center;height: 40px;padding-top:5px">COMPARE PRODUCTS</div>
				<div v-if="compareList.length > 1" style="margin-bottom: 20px;">
					<div  v-for="(item,index) in compareList" :key="item.id" style="margin: 10px 20px;display: flex;justify-content: space-between">
						<el-text  v-if="index >0" type="primary" style="justify-content:left;font-size: 16px;flex-grow: 1;margin-right: 20px;">{{ item.description }}</el-text>
						<!-- <div style="margin-top: 30px;margin-left: 70px;"> -->
						<!-- <div > -->
							<el-button type="text" @click="removeCompare(item)" style="text-align: right;" v-if="index >0">
								<el-icon size="16" style="vertical-align: middle">
									<Close/>
								</el-icon>
							</el-button>
						<!-- </div> -->
						
						<!-- </div> -->
						
					</div>
					<div style="margin: auto; width: 180px;">
						<router-link to="/pages/compare">
							<el-button v-if="compareList.length > 1"  type="primary" style="margin-top:20px">Compare</el-button>
						</router-link>
						
						<el-button v-if="compareList.length > 1"  type="danger" text style="margin-top:20px" @click="removeAllCompare()">Clear All</el-button>
					</div>
					
				</div>
				
				
				<div v-else style="text-align: center;font-size: 16px;font-weight:600;margin: 20px 0;">
					You have no items to compare.
				</div>
			</div>

			<!-- 收藏 -->
			<div style="margin-top: 50px;border: #eee solid 1px;border-radius: 10px;">
				<div style="background: #eee;font-size:20px;text-align: center;height: 40px;padding-top:5px">MY WISH LIST</div>
				<div v-if="wishList.length > 0" style="margin-bottom: 20px;">
					<div  v-for="item in wishList" :key="item.id" style="margin: 10px 20px;display: flex;justify-content: space-between">
						<el-text   type="primary" style="justify-content: left;font-size: 16px;flex-grow: 1;margin-right: 20px;">{{ item.description }}</el-text>
						<!-- <div style="margin-top: 30px;margin-left: 70px;"> -->
						<el-button type="text" @click="deleteWishList(item)" style="text-align: right;" >
							<el-icon size="16" style="vertical-align: middle">
								<Close/>
							</el-icon>
						</el-button>
						<!-- </div> -->
						
					</div>
					<div style="margin: auto;width: 80px;">	
						<router-link to="/pages/my?type=wishlist">
							<el-button v-if="compareList.length > 1" text  type="primary" style="margin-top:20px">More</el-button>
						</router-link>
					</div>
					
					
					<!-- <el-button v-if="compareList.length > 1"  type="danger" text style="margin-top:20px" @click="removeAllCompare()">Clear All</el-button> -->
				</div>
				
				
				<div v-else style="text-align: center;font-size: 16px;font-weight:600;margin: 20px 0;">
					You have no items in your wish list.
				</div>
			</div>

		</el-aside>
		<el-main style="margin-top: 80px;margin-right: 10vw;" >
			<el-row :gutter="0" style="min-height: 500px;" v-loading="productLoading">
				<el-col v-if="productList && productList.length > 0" :xs="12" :sm="12" :md="12" :lg="8" :xl="6" :xxl="6"
				        style="margin-bottom: 20px; cursor: pointer" v-for="item in productList"
				        :key="item">
					<router-link :to="`/pages/productDetail?productId=${item.id}`">
						<el-popover :show-arrow="false" :offset="-260" width="160" popper-class="cc" placement="top">
							<template #reference>
								<el-card style="width: 260px;height: auto;border: 0;margin-bottom: 30px;" shadow="never" :body-style="{ padding: '0px' }">
									<div style="width: 240px;height: 275px;overflow: hidden;">
										<el-image :src="item.imgs.split(',')[0]" style="margin-left: 10px;max-width: 100%;max-height: 100%;" fit="fill" class="productImg">
									
										</el-image>
									</div>
									
									<div style="text-align: center;margin-top: 10px;">
										<el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">{{ item.description }}</el-text>
									</div>
									<div style="margin-left: 10px;text-align:center">
										<template v-for="label in labelList" :key="label.id">
											<el-tag v-if="label.productId === item.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
										</template>
										<!-- <el-tag type="success" style="margin-right: 10px;margin-top: 10px;">For 0-1 Year Old</el-tag>
										<el-tag type="success" style="margin-right: 10px;margin-top: 10px;">For 0-1 Year Old</el-tag>
										<el-tag type="success" style="margin-right: 10px;margin-top: 10px;">For 0-1 Year Old</el-tag>
										<el-tag type="success" style="margin-right: 10px;margin-top: 10px;">Preschoolers (Age 3-6)</el-tag>
										<el-tag type="success" style="margin-right: 10px;margin-top: 10px;">Lower Elementary (Age 6-9) </el-tag> -->
									</div>
									<div style="text-align: center;margin-top: 10px;">
										<el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">${{
											floatToFixed2(item.price)
										}}
										</el-text>
										<el-text class="mx-1" tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
											${{ floatToFixed2(item.oprice) }}
										</el-text>
									</div>
								</el-card>
							</template>
							<template #default>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="cartImg"
										@click="addCart(item)"
										style="width: 35px;height: 35px;"
										alt="cart"
									/>
								</el-button>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="collectImg"
										@click="addWishList(item)"
										style="width: 30px;height: 30px;"
										alt="collect"
									/>
								</el-button>
								<el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
									<el-image
										:src="compareImg"
										@click="addCompare(item)"
										style="width: 30px;height: 30px;"
										alt="compare"
									/>
								</el-button>
							</template>
						</el-popover>
					</router-link>
				</el-col>
				<el-col v-else style="text-align: center; line-height: 500px; font-size: 18px">
					no more products
				</el-col>
			</el-row>
			<el-pagination
				v-model:current-page="paginationData.page"
				v-model:page-size="paginationData.pageSize"
				:total="paginationData.total"
				@current-change="handleCurrentChange"
				background layout="prev, pager, next"
				style="margin-left: 20vw;margin-top: 80px;"/>
		</el-main>
	</el-container>
	
	<pc-footer></pc-footer>
	
	
	<!-- 多个spu类型弹窗	-->
	<el-dialog v-model="dialogVisible" title="Please select product spec"  center align-center  style="width: 1000px;">
		
		<div style="margin: auto;padding-top: 30px;display: flex;justify-content: space-between;">
      <el-card style="border: 0px;" shadow="never" v-if="product">
        <el-image
          style="width: 400px; height: 400px;border: 1px solid #000;border-radius: 10px;margin-left: 5px;"
          :src="productImg"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="imgList"
          :initial-index="4"
          fit="cover"
        />
        <el-row v-if="product.imgs">
          <el-col :span="4"  v-for="item in product.imgs.split(',')" :key="item">
            <el-link :underline="false">
              <el-image :src="item" style="width: 50px;height: 50px;margin-top: 10px;border: 1px solid #d7d5d5;" @click="chanageImg(item)"></el-image>
            </el-link>
           

            
          </el-col>
        </el-row>
      </el-card>

      <div style="margin-top: 10px;text-align: left;width: 500px;">
        <div style="font-size: 30px;color: #000;font-weight: 600;margin-bottom: 20px;">
          <span>{{product.name}}</span>
          
        </div>
        <div style="margin-top: 20px;">
          <!-- <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 30px;">Market Price: </el-text> -->
          <el-text style="font-size: 24px;color: #000;font-weight: 600;margin-right: 30px;">$ {{floatToFixed2(product.price)}}</el-text>
          <el-text tag="del" style="margin-right: 30px;color: rgb(204, 102, 102);font-weight: 600;font-size: 20px;">$ {{floatToFixed2(product.oprice)}}</el-text>
          
        </div>

        <div style="margin-top: 20px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">You Save: {{1-(product.oprice/product.price).toFixed(2) * 100}}%</el-text>
        </div>

        <div style="margin-left: 10px;">
			<template v-for="label in labelList" :key="label.id">
            <el-tag v-if="label.productId === product.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
          </template>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <!-- <el-text style="font-size: 14px;color: #000;margin-right: 55px;">Sold: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.soldNum }}</span> </el-text>
          <el-text style="font-size: 14px;color: #000;margin-right: 55px;">Inventory: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.inventory }}</span></el-text> -->
		  <el-text style="font-size: 14px;color: #000;margin-right: 55px;"><span  style="font-size: 20px;color: #000;font-weight: 600;">{{ setInventoryMsg(product.inventory) }}</span></el-text>
		  <el-text style="font-size: 14px;color: #000;margin-right: 55px;" v-if="product.nextCome"> <span  style="font-size: 20px;color: #000;font-weight: 600;">Restock Time: {{ product.nextCome }}</span></el-text>
		</div>
		
		<div  style="margin-top: 50px;display: flex;">

			<div v-if="attr && attr.length > 0">
				<div v-for="item in attr" :key="item.key" style="margin-top: 20px">
					<div style="font-size: 14px;color: #000;font-weight: 500;margin-bottom: 10px;"> {{item.key}}</div>
					<el-radio-group v-model="chanceSpec[item.key]" size="large" @change="changeSpec(item.key)" >
					<template v-for="value in item.values" :key="value">
						<el-radio-button :label="value" :value="value" border style="margin-right: 30px;border-radius: 30px;" />
					</template>
					
					</el-radio-group>
				</div>
			</div>
		
			<div>
				<el-image v-if="product.specImg" :src="product.specImg" style="margin-left: 30px;width: 120px;height: 120px;"></el-image>
			</div>
		</div>
        

       
        <div style="color: #000;margin-bottom: 20px;margin-top: 50px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">Qty: </el-text>
          <el-input-number v-model="productNum" :min="1" :max="product.inventory" :step-strictly="true"  style="height: 50px;font-weight: 600;font-size: 30px;"/>
        </div>
        <div style="font-size: 30px;color: #fff;font-weight: 600;margin-bottom: 20px;margin-top: 20px;width: 380px;">
          

          <el-button round bg type="danger" style="width: 380px;height: 60px;font-size: 18px;font-weight: 600;margin-bottom: 10px;" @click="addCart">
            <el-icon size="30" style="vertical-align: middle;margin-right: 10px;">
              <ShoppingCartFull />
            </el-icon>
            Add to cart
          </el-button>

          <!-- <el-button type="success" text bg round style="width: 170px;height: 50px;font-size: 18px;font-weight: 600;">Add to wish list</el-button>
          
          <el-button type="warning" style="width: 170px;margin-left: 20px;font-weight: 600;font-size: 18px;height: 50px;" text bg round @click="addCompare">Add to compare</el-button> -->
        </div>
      </div>
    </div>
	</el-dialog>
</template>

<script setup>
import {ref, reactive, watch, defineOptions, onMounted, nextTick} from 'vue'
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {floatToFixed2} from "@/utils/priceUtil"
import {getAgeList, getCategoryList, getProductAllByPage, getProductLabelAll, getSpuAll} from "@/api/spu"
import {doAddWishList,getWishListAllByPage,doDeleteWishList} from "@/api/spu/wishList"
import cartImg from "@/assets/img/cart.png";
import collectImg from "@/assets/img/collect.png";
import compareImg from "@/assets/img/compare.png";

import titlebg from "@/assets/img/productlist.png";
import {useRoute, useRouter} from "vue-router";
import {useDebounceFn} from '@vueuse/core'
import useCartCache from "@/store/cart";
import useCompareCache from "@/store/compare";
import { ElMessage,ElMessageBox  } from 'element-plus'
import {getAccessToken} from "@/utils/auth";
import moment from 'moment-timezone';


defineOptions({
	name: "ProductList"
})

const route = useRoute();
const router = useRouter();
const cartCacheStore = useCartCache()
const compareCacheStore = useCompareCache()

const compareList = ref([])

const wishList = ref([])

// 选择spu弹窗相关(dialogType 用于区分是那种弹窗  cart/compare/wishList)
const dialogVisible = ref(false);
const product = ref({});
const productImg = ref();
const productNum = ref(1);
const chanceSpec = ref({});
const spuList = ref([]);
const attr = ref([{
	key: "颜色",
	value: ["红色", "蓝色", "绿色"]
}, {
	key: "尺寸",
	value: ["S", "M", "L"]
}]);

const labelList = ref([])

const productLoading = ref(false);


// 年龄/分类
const ageOptions = ref([]);
const categoryOptions = ref([])
const productList = ref([])
const sortOptions = ref([
	{
		id: "mostPopular",
		name: "Most Popular"

	},
	{
		id: "newArrivals",
		name: "New Arrivals"

	},
	{
		id: "cheapest",
		name: "Low to High"

	},
	{
		id: "expensive",
		name: "High to Low"

	},
	{
		id: "bestSalers",
		name: "Best Sellers"

	},
	{
		id: "sku",
		name: "SKU"

	},
	{
		id: "productName",
		name: "Product Name"

	},
])


// 表单数据
const formData = reactive({
	// priceValue: [0, 9999],
	category: null,
	age: [],
	orderPriceSort: 'DESC',
	sort:"mostPopular"
})

// 分页数据
const paginationData = reactive({
	page: 1,
	pageSize: 16,
	total: 0
})

const skipWatch = ref(false)

// //略过重新设置价格区间，因为会导致重新查询
const skipPrice = ref(false)

const currentPrice = ref([0,9999])

const currentMaxPrice = ref(9999)

const currentMinPrice = ref(0)

// 获取分类
const getCategory = async () => {
	const {data: {list}} = await getCategoryList({
		page: 1,
		pageSize: 20,
		conditions: []
	})
	categoryOptions.value = list;
	console.log(list, "分类数据");
}

// 获取年龄标签
const getAgeLabel = async () => {
	const {data: list} = await getAgeList({
		
		conditions: []
	})
	// list.forEach((item,index) => {
	// 	if(item.id == 9){
	// 		list.splice(index,1)
	// 	}
	// })
	ageOptions.value = list;
	console.log(list, "年龄分类数据");
}

const getLabelName = (id) =>{
	for (let i = 0; i < ageOptions.value.length; i++) {
		if(ageOptions.value[i].id == id){
			return ageOptions.value[i].name
		}
	}
}

// 查询商品(等待一秒之后查询，用于处理滑块频繁改变触发查询)
const queryProductList = useDebounceFn(async () => {
	productLoading.value = true
	const queryParam = await convertDataToQueryParam();
	const {data: {list = [], total = 0}} = await getProductAllByPage(queryParam);
	productList.value = list;
	paginationData.total = total;

	//获取最高价和最低价
	 if(!skipPrice.value){
		skipWatch.value =true
		// await nextTick();
		// formData.priceValue[1] = (Math.max(...list.map(item => item.price))/100).toFixed(2)
		// formData.priceValue[0] = (Math.min(...list.map(item => item.price))/100).toFixed(2)
		if(list.length>0){
			queryParam.conditions.push(queryWrapper("price", QueryCondition.DESC,[]))
			queryParam.page =1;
			queryParam.pageSize =1;
			const {data: {list : maxlist}} = await getProductAllByPage(queryParam);
			currentMaxPrice.value = (maxlist[0].price/100).toFixed(2)
			queryParam.conditions.push(queryWrapper("price", QueryCondition.ASC,[]))
			const {data: {list : minlist}} = await getProductAllByPage(queryParam);
			currentMinPrice.value = (minlist[0].price/100).toFixed(2)
			// currentMaxPrice.value = (Math.max(...list.map(item => item.price))/100).toFixed(2)-0 
			// currentMinPrice.value =  (Math.min(...list.map(item => item.price))/100).toFixed(2)-0 
		}else{
			currentMaxPrice.value = 300
			currentMinPrice.value = 0
		}
		
		// if(currentMaxPrice.value < currentPrice.value[1]){
			currentPrice.value[1] = currentMaxPrice.value;
		
			currentPrice.value[0] = currentMinPrice.value;
		
		await convertFormDataToUrlQuery()
		skipWatch.value = false;
		// await nextTick();

		//重置略过价格区间设置
		// skipPrice.value = false;
	}
	
	
	if(list && list.length > 0){
		let array = [];
		for (let i = 0; i < productList.value.length; i++) {
			array.push(productList.value[i].id)
		}

		//查询商品标签
		const {data:  labels} = await getProductLabelAll({
			conditions: [queryWrapper("productId", QueryCondition.IN, array)]
		})
		labelList.value = labels;
	}
	
	// 查询完成后浏览器跳到顶端
	document.documentElement.scrollTo({
		top: 0
	})
	productLoading.value = false
	console.log(list, "商品数据");
}, 1000)


// 查询商品条件转换
const convertDataToQueryParam = async () => {
	
	const urlQuery = route.query ? route.query : {};
	
	const queryParam = {
		page: paginationData.page,
		pageSize: paginationData.pageSize,
		conditions: [queryWrapper("status", QueryCondition.EQ, 1)]
	}
	
	// 处理关键词keyword(拼接名称和描述的模糊查询)
	if (urlQuery && urlQuery.keyword) {
		queryParam.conditions.push(queryWrapper("name,description", QueryCondition.OR, [route.query.keyword,route.query.keyword]))
		// queryParam.conditions.push(queryWrapper("description", QueryCondition.ALL, route.query.keyword ))
	}
	
	// 转换价格区间(美元转美分)
	if (urlQuery.minOldPrice && urlQuery.maxOldPrice && skipPrice.value) {
		// if(urlQuery.maxOldPrice < currentMaxPrice.value){
			queryParam.conditions.push(queryWrapper("price", QueryCondition.LT, Number(currentPrice.value[1]) * 100))
		// }
		// if(urlQuery.minOldPrice > currentMinPrice.value){
			queryParam.conditions.push(queryWrapper("price", QueryCondition.GT, Number(currentPrice.value[0]) * 100))
		// }
		
		
	}
	
	// 转换分类
	if (urlQuery.categoryId) {
		queryParam.conditions.push(queryWrapper("categoryId", QueryCondition.EQ, urlQuery.categoryId))
	}
	
	// 转换年龄（标签）
	if (urlQuery.labelId) {
		queryParam.conditions.push(await convertAgeLabel(urlQuery.labelId))
	}
	
	//  转换价格排序
	if (urlQuery.sort === 'mostPopular') {
		queryParam.conditions.push(queryWrapper("sort", QueryCondition.DESC, []))
	} else if(urlQuery.sort === 'expensive'){
		queryParam.conditions.push(queryWrapper("price", QueryCondition.DESC, []))
	} else if(urlQuery.sort === 'newArrivals'){
		queryParam.conditions.push(queryWrapper("create_time", QueryCondition.DESC, []))
	} else if(urlQuery.sort === 'bestSalers'){
		queryParam.conditions.push(queryWrapper("sold_num", QueryCondition.DESC, []))
	} else if(urlQuery.sort === 'cheapest'){
		queryParam.conditions.push(queryWrapper("price", QueryCondition.ASC, []))
	} else if(urlQuery.sort === 'sku'){
		queryParam.conditions.push(queryWrapper("name", QueryCondition.ASC, []))
	} else if(urlQuery.sort === 'productName'){
		queryParam.conditions.push(queryWrapper("description", QueryCondition.ASC, []))
	}
	
	
	return queryParam;
}


// form数据转化为url query
const convertFormDataToUrlQuery = async () => {
	
	const urlQuery = route.query ? {...route.query} : {}
	
	// 转换价格区间(美元转美分)
	if (currentPrice.value && currentPrice.value.length > 0) {
		urlQuery.minOldPrice = currentPrice.value[0];
		urlQuery.maxOldPrice = currentPrice.value[1];
	} else {
		delete urlQuery.minOldPrice;
		delete urlQuery.maxOldPrice;
	}
	
	// 转换分类
	if (formData.category) {
		urlQuery.categoryId = formData.category;
	} else {
		delete urlQuery.categoryId;
	}
	
	// 转换年龄（标签）
	if (formData.age && formData.age.length > 0) {
		urlQuery.labelId = formData.age;
	} else {
		delete urlQuery.labelId;
	}
	
	//  转换价格排序
	// if (formData.orderPriceSort === 'DESC') {
	// 	urlQuery.sort = 'DESC';
	// } else {
	// 	urlQuery.sort = 'ASC';
	// }
	
	urlQuery.sort = formData.sort;
	
	console.log(urlQuery, "urlQuery");
	
	await router.replace({
		query: urlQuery
	})
	
}

//  单独处理年龄，先查询所有的标签商品，再通过所有的标签商品id查询商品表
const convertAgeLabel = async (labelId) => {
	// 查询所有的商品标签
	const queryParam = {conditions: []}
	queryParam.conditions.push(queryWrapper("labelId", QueryCondition.IN, labelId))
	const {data = []} = await getProductLabelAll(queryParam);
	
	// 循环获取对应的商品id,并返回对应的条件
	const productIds = [];
	data.forEach(item => {
		productIds.push(item.productId)
	})
	
	// 存在没数据的情况，导致后台查询报错
	if (productIds.length === 0) productIds.push("")
	return queryWrapper("id", QueryCondition.IN, productIds)
}

// 处理页签变化
const handleCurrentChange = (pageVal) => {
	paginationData.page = pageVal;
	queryProductList();
}


// 判断是否从头部导航或者搜索栏进入(通过不存在maxOldPrice属性判断)
const checkInitFromSearch = () => {
	const query = route.query ? route.query : {};
	
	if (query.maxOldPrice === undefined) {
		return true;
	}
}

// 每次重新输入关键词或者点击导航栏重置查询条件
const restFormDataAndPaginationData = () => {
	const query = route.query ? route.query : {}
	
	// formData.priceValue = [0, 9999];
	formData.category = query.categoryId ? Number(query.categoryId) : null;
	formData.age = query.labelId ? [Number(query.labelId)] : [];
	formData.orderPriceSort = 'DESC';
	formData.sort = query.sort ? query.sort : 'mostPopular';
	
	paginationData.page = 1;
	paginationData.pageSize = 16;
	paginationData.total = 0;
}


// 转化数据为实际可选项
const convertSpec = (spuList) => {
	const specList = []
	spuList.forEach(item => {
		const specDesc = item.specDesc ? JSON.parse(item.specDesc) : []; // 转化JSON 字符串 [{key:string, value:string}]
		item.specDesc = specDesc // 转化JSON 字符串
		if(specDesc){
			specDesc.forEach(spec => {
			
				// 判断key是否已经存在
				const index = specList.findIndex(item => item.key === spec.key);
				if (index === -1) {
					specList.push({
						key: spec.key,
						value: [spec.value]
					})
				} else {
					// 判断value是否存在, 不存在就添加
					const valueIndex = specList[index].value.indexOf(spec.value);
					if (valueIndex === -1) specList[index].value.push(spec.value);
				}
			})
		}
		
	})
	
	return specList;
}

// 用于判断是否需要打开弹窗(判断商品对应的spu是否存在多条)
const checkOpenDialog = async (item) => {

	product.value = item;
	productImg.value = item.imgs.split(',')[0]
	const {data = []} = await getSpuAll({
		conditions: [queryWrapper("productId", QueryCondition.EQ, item.id),queryWrapper("sort", QueryCondition.DESC, "")]
	});
	spuList.value = data;
	if (data && data.length > 1) {
		dialogVisible.value = true;
		// specTypeList.value = convertSpec(data);
		attr.value = JSON.parse(item.attr);

      const specDesc = JSON.parse(data[0].specDesc);
	  if(specDesc){
		for(let i = 0; i < specDesc.length; i++){
			const spec = specDesc[i];
			chanceSpec.value[spec.key] = spec.value;
		}
	  }
      

      product.value.soldNum = data[0].soldNum;
      product.value.inventory = data[0].inventory;
      product.value.oprice = data[0].oprice;
      product.value.price = data[0].price;
	  return true;
	}
	return false
}


// 处理添加购物车
const addCart = async (item) => {
	
	if(!dialogVisible.value){
		item.num = 1;
		const dialogVisible = await checkOpenDialog(item)
		// 如果打开弹窗直接返回
		if (dialogVisible) return;
		
		if(spuList.value[0].inventory < item.num){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}

		// 未打开直接添加购物车，并写入productSpecId
		const specDesc = spuList.value[0].specDesc ? JSON.stringify(spuList.value[0].specDesc) : [];
		item.inventory = spuList.value[0].inventory
		await cartCacheStore.addCartItem({...item, productSpecId: spuList.value[0].id, specDesc: specDesc})
		cartCacheStore.cartDrawer = true
	}else{
		product.value.num = productNum.value
		let spec ={}
		let array = []
		for (const [key, value] of Object.entries(chanceSpec.value)) {
			const s = {
			key: key,
			value: value
			};
			array.push(s);
		}
		spuList.value.forEach((value) => {
			
			if(value.specDesc == JSON.stringify(array)){
			
			spec = value;
			return;
			}
		});
		if(!spec.id || spec.inventory < productNum.value){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}
		await cartCacheStore.addCartItem({...product.value, productSpecId: spec.id, specDesc: array})
		cartCacheStore.cartDrawer = true
	}
	ElMessage({
          message: "Successfully added to shopping cart",
          type: "success"
        });
	
}

// 处理添加比较
const addCompare = (item) => {
	//compareCacheStore.addCompareItem(item)
	compareList.value = JSON.parse(localStorage.getItem("compareList")) || [{}];
	var flag = true

	
	for(let i = 0; i < compareList.value.length; i++){
		if(compareList.value[i].id === item.id){
			flag = false;
			break;
		}
	}
	
	if(flag){
		
		compareList.value.push(item);
		localStorage.setItem("compareList", JSON.stringify(compareList.value));
		ElMessageBox.confirm(
			'Would you like to check the comparison list?',
			'Success!',
			{
			confirmButtonText: 'OK',
			cancelButtonText: 'Cancel',
			type: 'success',
			}
		).then(() => {
			router.push({path: '/pages/compare',})
		})
		.catch(() => {
		
		})
	}else{
		
		ElMessage({
			message: "The product already exists in the comparison list",
			type: "warning"
		});
	}
}

//删除比较
const removeCompare = (item) => {
	for(let i = 0; i < compareList.value.length; i++){
		if(compareList.value[i].id === item.id){
			compareList.value.splice(i, 1);
			break;
		}
	}
	localStorage.setItem("compareList", JSON.stringify(compareList.value));
 }

 const removeAllCompare = (item) => {
	compareList.value = [{}]
	localStorage.setItem("compareList", JSON.stringify(compareList.value));
 }

// // 处理添加心愿单
// const addWishList = async (item) => {
// 	await doAddWishList(item)
// 	ElMessage({
// 		message: "加入心愿清单成功！",
// 		type: "success"
// 	});
// }

// 监听地址栏变化
watch(() => route.query, async (data) => {
	// 判断是否来源于搜索或者导航栏，直接重置所有查询条件，然后通过变更formData数据来触发地址栏的变更
	const isFromSearch = checkInitFromSearch()
	if (isFromSearch) {
		restFormDataAndPaginationData();
		return
	}

	if(skipWatch.value){
		return
	}
	
	await queryProductList()
}, {
	immediate: true,
	deep: true
})

// 监听formData 数据变化
watch(formData, async (data) => {
	await convertFormDataToUrlQuery()
}, {
	immediate: true
})

onMounted(async () => {
	await getCategory(); // 获取分类
	await getAgeLabel(); // 获取年龄
	compareList.value = JSON.parse(localStorage.getItem("compareList")) || [{}];
	await getWishList();
	// restFormDataAndPaginationData();
	// console.log("this.$route.query",route.query)
	// const path = router.path.split("?")[1];
	// if(path){
	// 	const param = path.split("&");
	// 	for(let i=0;i<param.length;i++){
	// 		const key = param[i].split("=")[0];
	// 		const value = param[i].split("=")[1];
	// 		if(key === "categoryId"){
	// 			formData.category = value;
	// 		}
	// 		if(key === "ageId"){
	// 			formData.age.push(value);
	// 		}
	// 	}
	// }
	// if (route.query.categoryId) {
	// 	formData.category = route.query.categoryId;
	// }
	// if (route.query.labelId) {
	// 	formData.age = route.query.labelId;
	// }
})

const chanageImg = (item) =>{
      productImg.value = item
 }

 const changeSpec = () =>{
      let array = []
     
      for (const [key, value] of Object.entries(chanceSpec.value)) {
        const s = {
          key: key,
          value: value
        };
        array.push(s);
      }
	  product.value.soldNum = 0;
		product.value.inventory = 0;
		product.value.oprice = 0;
		product.value.price = 0;
		product.value.nextCome = null;
		product.value.specImg = null;
      spuList.value.forEach((value) => {
        
        if(value.specDesc == JSON.stringify(array)){
          
          product.value.soldNum = value.soldNum;
          product.value.inventory = value.inventory;
          product.value.oprice = value.oprice;
          product.value.price = value.price;
		  product.value.nextCome = value.nextCome ?  moment(value.nextCome).tz('America/New_York').format("YYYY-MM-DD") : null;
		  product.value.specImg = value.imgs.split(',')[0];
          return;
        }
      });
    }

	const addWishList = async (item) => {
		
      if(getAccessToken()){
		await doAddWishList({productId: item.id});
		ElMessage({
			message: "Successfully added to the wish list",
			type: "success"
		});
		await getWishList();
      }else{
		ElMessageBox.confirm(
          'You are not logged in. Would you like to log in now?',
          'Error',
          {
          confirmButtonText: 'OK',
          cancelButtonText: 'Cancel',
          type: 'error',
          }
        ).then(() => {
			router.push({path: '/pages/login',query:{
				redirect: router.currentRoute.value.fullPath
			}})
        })
        .catch(() => {
        
        })
	  }
    }

	const setInventoryMsg = (inventory) =>{
      if(inventory > 15){
        return 'In stock';
      }else if(inventory == 0){
        return 'Out of stock';
      }else{
        return 'Only '+inventory+' left in stock - order soon.';
      }
    
	}

	const changePrice = async() => {
		skipPrice.value = true;
		// await convertFormDataToUrlQuery()
		await queryProductList();
		skipPrice.value = false;
	}

	const getWishList = async () => {
		
		if(getAccessToken()){
			const {data:{list}} = await getWishListAllByPage(
				{page: 1, pageSize: 6, conditions: [queryWrapper("create_time", QueryCondition.DESC, "")]}
			)
			if(list.length >0){
				var productIds = [];
				for(var i = 0; i < list.length; i++){
				productIds.push(list[i].productId);
				}
				const {data: {list:pList}} =  await getProductAllByPage({
					page: 1,
					pageSize: 6,
					conditions: [queryWrapper("id", QueryCondition.IN, productIds)]
				})
				
				wishList.value = pList;
			}else{
				wishList.value = [];
  			}
			
		}
		
	}

	const deleteWishList = async (item) => {
		//删除收藏
		await doDeleteWishList({productId: item.id})
		getWishList();
	}

	const setCateGoryName = () =>{
		const urlQuery = route.query ? route.query : {};
		console.log("urlQuery",urlQuery.categoryId)
		// const foundItem = categoryOptions.value.find(item => {
		// 	console.log("item.id",item.id)
		// 	return item.id === urlQuery.categoryId
		// });

		let name = '';
		for(let i = 0; i < ageOptions.value.length; i++){
			
			if(urlQuery.labelId && ageOptions.value[i].id+'' === urlQuery.labelId[0]){
				name = ageOptions.value[i].name;
				return name;
			}
		}

		for(let i = 0; i < categoryOptions.value.length; i++){
			if(categoryOptions.value[i].id+'' === urlQuery.categoryId){
				name = categoryOptions.value[i].name;
			}
		}
		return name ? name : "ALL";
		
	}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.search-container /deep/ .el-form-item__content {
	justify-content: flex-end;
}

.productImg:hover {
	transform: scale(1.2);
}

.productImg {
	transition: transform 0.3s ease-in-out;
	
}
</style>
