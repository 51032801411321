import request from "@/utils/request/index"


// 个人信息
export const my = (data) => {
  return request({
    url: "/openapi/customer/my",
    data
  })
}


export const customerEdit = (data) => {
  return request({
    url: "/openapi/customer/update",
    data
  })
}

