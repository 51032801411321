<template>
  <pc-header/>

    <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
      <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Compare</div>
      <div> 
        <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span>Compare</span>
        </div>
    </div>

    <div style="margin: 80px auto; width: 70vw;min-width: 1200px;">
      <div v-if="compareData.length==1" style="height: 300px;">
        <el-alert title="You have no items to compare." type="warning" :closable="false" style="zoom: 150%;" center show-icon/>
        <router-link to="/pages/productList">
          <div style="margin:auto;width: 240px;margin-top: 30px;">
            <el-link type="primary" style="font-size: 24px;">go to shopping >></el-link>
          </div>
        </router-link>
        
      </div>
      <el-table border :data="tableTitle" v-else>
        <el-table-column
          v-for="(item, index) in compareData"
          :label="item.name"
          :key="index"
          min-width="250"
          :fixed="index===0"
        >
        <template #header>
          <template v-if="index===0"><span style="font-size: 18px;font-weight: 600;">SKU</span></template>
          <template v-else>
            <div style="float: left;"><span style="text-align: center;">{{ item.name }}</span></div>
            <!-- <el-button  :icon="Delete" style="text-align: right;" circle /> -->
            <div>
              <el-button type="text" @click="remove(item)" style="float: right;">
                <el-icon size="20" >
                  <Close />
                </el-icon>
              </el-button>
            </div>
            
          </template>
          
        </template>
        <template #default="scope">
          <template v-if="index===0"><span style="font-size: 18px;font-weight: 600;">{{ tableTitle[scope.$index] }}</span></template>
          <template v-if="index!=0 && scope.row == 'MESSAGE'">
            <router-link :to="`/pages/productDetail?productId=${item.id}`">
              <el-image :src="item.imgs.split(',')[0]" style="width: 200px;height: 200px;margin-left: 10px;margin-top: 10px;margin-bottom: 10px;"></el-image>
            
              <div style="margin-bottom: 30px;">
                <el-link type="primary" href="" >Addition Strip Board</el-link>
              </div>
            </router-link>
            
            <div style="margin-top: 10px;margin-bottom: 20px;">
              <!-- <el-text style="font-size: 16px;">Market Price: </el-text> -->
              <el-text style="margin-left:10px;margin-right: 10px;font-size: 20px;font-weight: 600;">${{ setPrice(item.price) }}</el-text>
              <el-text tag="del"  style="font-size: 16pxpx;color: rgb(204, 102, 102);">${{ setPrice(item.oprice) }}</el-text>
            </div>
            <!-- <div style="margin-bottom: 30px;">
              <el-text style="font-size: 16px;color: margin-right: 55px;">Your Price: </el-text>
              
            </div> -->
            <div style="margin-bottom: 30px;">
              
              <el-button round bg type="danger" style="width: 160px;height: 40px;font-size: 18px;font-weight: 600;margin-right: 10px;" @click="addCart(item)">
                <el-icon size="30" style="vertical-align: middle;">
                  <ShoppingCartFull />
                </el-icon>
                Add to cart
              </el-button>

              <el-button   circle style="width: 40px;height: 40px;margin-left: 0;vertical-align: middle;" @click="addWishList(item)">
                    <el-image
                      :src="collectImg"
                      style="width: 30px;height: 30px;"
                      alt="collect"
                    />
              </el-button>
            </div>
          </template>
          <template v-if="index!=0 && scope.row == 'DESCRIPTION'">
            <span>{{ item.description }}</span>
          </template>
        </template>
        </el-table-column>
      </el-table>
    </div>


    <!-- 多个spu类型弹窗	-->
	<el-dialog v-model="dialogVisible" title="Please select product spec"  center align-center >
		
		<div style="margin: auto;padding-top: 30px;display: flex;justify-content: space-between;">
      <el-card style="border: 0px;" shadow="never" v-if="product">
        <el-image
          style="width: 400px; height: 400px;border: 1px solid #000;border-radius: 10px;margin-left: 5px;"
          :src="productImg"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="imgList"
          :initial-index="4"
          fit="cover"
        />
        <el-row v-if="product.imgs">
          <el-col :span="4"  v-for="item in product.imgs.split(',')" :key="item">
            <el-link :underline="false">
              <el-image :src="item" style="width: 50px;height: 50px;margin-top: 10px;border: 1px solid #d7d5d5;" @click="chanageImg(item)"></el-image>
            </el-link>
           

            
          </el-col>
        </el-row>
      </el-card>

      <div style="margin-top: 10px;text-align: left;width: 500px;">
        <div style="font-size: 36px;color: #000;font-weight: 600;margin-bottom: 20px;">
          <span>{{product.name}}</span>
          
        </div>
        <div style="margin-top: 20px;">
          <!-- <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 30px;">Market Price: </el-text> -->
          <el-text style="font-size: 30px;color: #000;font-weight: 600;margin-right: 30px;">$ {{setPrice(product.price)}}</el-text>
          <el-text tag="del" style="margin-right: 30px;color: rgb(204, 102, 102);font-weight: 600;font-size: 24px;">$ {{setPrice(product.oprice)}}</el-text>
          
        </div>
        <!-- <div style="margin-bottom: 20px;">
          <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 55px;">Your Price: </el-text>
         
        </div> -->
        <div style="margin-left: 10px;">
			<template v-for="label in labelList" :key="label.id">
            <el-tag v-if="label.productId === product.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
          </template>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Sold: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.soldNum }}</span> </el-text>
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Inventory: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.inventory }}</span></el-text>
        </div>

        <div v-if="attr && attr.length > 0" style="margin-top: 50px">
          <div v-for="item in attr" :key="item.key" style="margin-top: 20px">
            <div style="font-size: 20px;color: #000;font-weight: 500;margin-bottom: 10px;"> {{item.key}}</div>
            <el-radio-group v-model="chanceSpec[item.key]" size="large" @change="changeSpec(item.key)" >
              <template v-for="value in item.values" :key="value">
                <el-radio-button :label="value" :value="value" border style="margin-right: 30px;border-radius: 30px;" />
              </template>
              
            </el-radio-group>
          </div>
          
        </div>

       
        <div style="color: #000;margin-bottom: 20px;margin-top: 50px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">Qty: </el-text>
          <el-input-number v-model="productNum" :min="1" :max="product.inventory" :step-strictly="true"  style="height: 50px;font-weight: 600;font-size: 30px;"/>
        </div>
        <div style="font-size: 30px;color: #fff;font-weight: 600;margin-bottom: 20px;margin-top: 20px;width: 380px;">
          

          <el-button round bg type="danger" style="width: 380px;height: 60px;font-size: 18px;font-weight: 600;margin-bottom: 10px;" @click="addCart">
            <el-icon size="30" style="vertical-align: middle;margin-right: 10px;">
              <ShoppingCartFull />
            </el-icon>
            Add to cart
          </el-button>

          <!-- <el-button type="success" text bg round style="width: 170px;height: 50px;font-size: 18px;font-weight: 600;">Add to wish list</el-button>
          
          <el-button type="warning" style="width: 170px;margin-left: 20px;font-weight: 600;font-size: 18px;height: 50px;" text bg round @click="addCompare">Add to compare</el-button> -->
        </div>
      </div>
    </div>
	</el-dialog>
    
    <pc-footer/>
</template>

<script >
import { ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import {floatToFixed2} from "@/utils/priceUtil"
import {getProductById, getSpuAll,getAgeList,getProductLabelAll} from "@/api/spu"
import useCartCache from "@/store/cart";
import useCompareCache from "@/store/compare";
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {doAddWishList} from "@/api/spu/wishList"
import { ElMessage,ElMessageBox  } from 'element-plus'
import {getAccessToken} from "@/utils/auth";
import {useRoute, useRouter} from "vue-router";

export default {
  name: 'ProductCompare',
  components: {
    Delete
  },
  props: {
    msg: String
  },
  data() {
    return {
      collectImg:require("@/assets/img/collect.png"),
      compareCacheStore : useCompareCache(),
      cartCacheStore : useCartCache(),
      router: useRouter(),
      tableTitle:[
        "MESSAGE",
        "DESCRIPTION"
      ],
      compareData: [
        {},
       
      ],
      dialogVisible:false,
      spuList:[],
      productNum:1,
      attr:[],
      labelList:[],
      chanceSpec:{},
      product:{},
      ageOptions:[],
    }
    
  },
  created() {
   
   this.getCompareData();
   this.getAgeLabel();
  },
  methods: {
      getCompareData(){
        console.log("this.compareCacheStore.compareList",this.compareCacheStore.compareList)
        this.compareData = JSON.parse(localStorage.getItem("compareList")) || [{}];
      },
      setPrice(value){
        return (value/100).toFixed(2);
      },
      remove(item){
        for(let i = 0; i < this.compareData.length; i++){
          if(this.compareData[i].id === item.id){
            this.compareData.splice(i, 1);
            break;
          }
        }
        localStorage.setItem("compareList", JSON.stringify(this.compareData));
      },
      // 获取年龄标签
      async getAgeLabel  ()  {
        const {data: list} = await getAgeList({
          
          conditions: []
        })
        // list.forEach((item,index) => {
        // 	if(item.id == 9){
        // 		list.splice(index,1)
        // 	}
        // })
        this.ageOptions = list;
        console.log(list, "年龄分类数据");
      },
      async addCart(item){

        if(!this.dialogVisible){
          item.num = 1;
          const dialogVisible = await this.checkOpenDialog(item)
          // 如果打开弹窗直接返回
          if (dialogVisible) return;
          
          if(this.spuList[0].inventory < item.num){
            ElMessage({
            message: "Out of stock",
            type: "error"
            });
            return;
          }
          // 未打开直接添加购物车，并写入productSpecId
          const specDesc = this.spuList[0].specDesc ? JSON.stringify(this.spuList[0].specDesc) : [];
          item.inventory = this.spuList[0].inventory
          await this.cartCacheStore.addCartItem({...item, productSpecId: this.spuList[0].id, specDesc: specDesc})
          this.cartCacheStore.cartDrawer = true
        }else{
          this.product.num = this.productNum
          let spec ={}
          let array = []
          for (const [key, value] of Object.entries(this.chanceSpec)) {
            const s = {
            key: key,
            value: value
            };
            array.push(s);
          }
          this.spuList.forEach((value) => {
            
            if(value.specDesc == JSON.stringify(array)){
            
            spec = value;
            return;
            }
          });
          if(!spec.id || spec.inventory < this.productNum){
            ElMessage({
            message: "Out of stock",
            type: "error"
            });
            return;
          }
          await this.cartCacheStore.addCartItem({...this.product, productSpecId: spec.id, specDesc: array})
          this.cartCacheStore.cartDrawer = true
        }
        ElMessage({
                message: "Successfully added to shopping cart",
                type: "success"
              });
      },
      // 用于判断是否需要打开弹窗(判断商品对应的spu是否存在多条)
      async checkOpenDialog (item) {

        this.product = item;
        this.productImg = item.imgs.split(',')[0]
        const {data = []} = await getSpuAll({
          conditions: [queryWrapper("productId", QueryCondition.EQ, item.id),queryWrapper("sort", QueryCondition.DESC, "")]
        });
        this.spuList = data;
        if (data && data.length > 1) {
          this.dialogVisible = true;
          // specTypeList.value = convertSpec(data);
          this.attr = JSON.parse(item.attr);

          const specDesc = JSON.parse(data[0].specDesc);
          if(specDesc){
          for(let i = 0; i < specDesc.length; i++){
            const spec = specDesc[i];
            this.chanceSpec[spec.key] = spec.value;
          }
          }
            

          this.product.soldNum = data[0].soldNum;
          this.product.inventory = data[0].inventory;
          this.product.oprice = data[0].oprice;
          this.product.price = data[0].price;

          
          //查询商品标签
          const {data:  labels} = await getProductLabelAll({
            conditions: [queryWrapper("productId", QueryCondition.EQ, item.id)]
          })
          this.labelList = labels;
          return true;
        }
        return false
      },
       getLabelName (id) {
        for (let i = 0; i < this.ageOptions.length; i++) {
          if(this.ageOptions[i].id == id){
            return this.ageOptions[i].name
          }
        }
      },
      async addWishList (item) {
		
          if(getAccessToken()){
            await doAddWishList({productId: item.id});
            ElMessage({
              message: "Successfully added to wish list",
              type: "success"
            });
          }else{
            ElMessageBox.confirm(
              'You are not logged in. Would you like to log in now?',
              'Error',
              {
              confirmButtonText: 'OK',
              cancelButtonText: 'Cancel',
              type: 'error',
              }
            ).then(() => {
              debugger
              // this.router.push({path: '/pages/login'})
              this.router.push({path: '/pages/login',query:{
                redirect: this.router.currentRoute.fullPath
              }})
            })
            .catch(() => {
            
            })
          }
      },
      changeSpec () {
        let array = []
      
        for (const [key, value] of Object.entries(this.chanceSpec)) {
          const s = {
            key: key,
            value: value
          };
          array.push(s);
        }
        this.product.soldNum = 0;
        this.product.inventory = 0;
        this.product.oprice = 0;
        this.product.price = 0;
        this.spuList.forEach((value) => {
          
          if(value.specDesc == JSON.stringify(array)){
            
            this.product.soldNum = value.soldNum;
            this.product.inventory = value.inventory;
            this.product.oprice = value.oprice;
            this.product.price = value.price;
            return;
          }
        });
        
      },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }
</style>
