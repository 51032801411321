import axios from "axios";
import useCache from "@/utils/useCache";
import {getAccessToken, AccessTokenName, injectTokenNormal, injectTenantId,removeToken} from "@/utils/auth";
import { ElMessage,ElMessageBox  } from 'element-plus'
import {useRoute, useRouter} from "vue-router";
import {ref, reactive, watch} from "vue"
import {defineStore} from "pinia"


const router = useRouter();

const requestTimeout = 10000;
const contentType = "application/json;charset=UTF-8"
const  successCode = [1001];  // 正常状态码

const invalidCode = 1010; //登录失效code
const	noPermissionCode = 401; //无权限code

const showConfim = ref(1);


const handleCode = (code, msg) => {
  
  switch (code) {
    case invalidCode:
      // console.log("router.value.path",router)
      removeToken();
      // if(showConfim.value && getAccessToken()){
        
      //   showConfim.value = 0;
      //   ElMessageBox.confirm(
      //     '登录过期，是否前往登录?',
      //     'login error',
      //     {
      //     confirmButtonText: 'OK',
      //     cancelButtonText: 'Cancel',
      //     type: 'error',
      //     }
      //   ).then(() => {
      //     router.push({path: '/pages/login'})
      //   })
      //   .catch(() => {
          
      //   })
      // }
      // debugger  
      
      // if(router.value.path == '/cunstomer'){
      //   router.push({path: '/pages/login'})
      // }
      

      // console.log(msg || `后端接口${code}异常`, 'error')
      break
    case noPermissionCode:
      console.log(msg || `后端接口${code}异常`, 'error')
      break
    default:
      ElMessage({
        message: msg,
        type: "error"
      });
  }
}


const request = axios.create({
  timeout: requestTimeout,
  method: "post",
  headers: {
    'Content-Type': contentType
  },
});


// 请求拦截器
request.interceptors.request.use(
  async (config) => {
    const wsStore = useCache();

    // if (
    // 	config.data &&
    // 	config.headers['Content-Type'] ===
    // 	'application/x-www-form-urlencoded;charset=UTF-8'
    // ){
    // 	config.data = qs.stringify(config.data)
    // }

    // 为请求添加 token
    injectTokenNormal(config)

    // 为请求添加商户Id
    injectTenantId(config)


    return config;
  },
  (error) => {
    return Promise.reject(error)
  }
)

//添加响应拦截器，响应拦截器会在then/catch处理之前执行
request.interceptors.response.use(
  (response) => {

    const { data, config } = response

    const { code, msg } = data;
    
    // 是否操作正常
    if (successCode.includes(code)) {
      if(getAccessToken() && showConfim.value ==0){
        showConfim.value = 1;
      }
      return data
    } else {
      handleCode(code, msg)
      return Promise.reject(
        '请求异常拦截:' + JSON.stringify({ url: config.url, code, msg }) ||
        'Error'
      )
    }
  },
  (error) => {
    //发生网络错误后会走到这里

    const { response, message } = error
    if (error.response && error.response.data) {
      const { status, data } = response
      handleCode(status, data.msg || message);
      return Promise.reject(error)
    } else {
      let { message } = error
      if (message === 'Network Error') {
        message = '后端接口连接异常'
      }
      if (message.includes('timeout')) {
        message = '后端接口请求超时'
      }
      if (message.includes('Request failed with status code')) {
        const code = message.substr(message.length - 3)
        message = '后端接口' + code + '异常'
      }
      console.log(message || `后端接口未知异常`, 'error')
      return Promise.reject(error)
    }
  }
)

export default request;
