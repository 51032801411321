// 购物车接口

import request from "@/utils/request/index"


// 获取购物车清单（分页）
export const getCartAllByPage = (data) => {
  return request({
    url: "/openapi/cart/getAllByPage",
    data
  })
}


// 获取收藏清单
export const getCartAll = (data) => {
  return request({
    url: "/openapi/cart/queryAll",
    data
  })
}

// 添加收藏
export const doAddCart = (data) => {
  return request({
    url: "/openapi/cart/create",
    data
  })
}

export const doDeleteCart = (data) => {
  return request({
    url: "/openapi/cart/delete",
    data
  })
}

export const doUpdateCart = (data) => {
  return request({
    url: "/openapi/cart/update",
    data
  })
}