import request from "@/utils/request";

export const findByOrderId = (data) => {
    return request({
        url: "/openapi/order/findByOrderId",
        data
    })
}

export const getListByPage = (data) => {
    return request({
        url: "/openapi/order/getAllByPage",
        data
    })
}

export const getOrderProductList = (data) => {
    return request({
        url: "/openapi/orderProduct/getAllByPage",
        data
    })
}