/**
 *  用于处理图片地址
 *
 *
 **/
import {isEmpty} from '@/utils/is'

// 用于获取图片地址中的第一张图片
export const getDefaultImgFile = (imgUrls) => {
  return getImgUrlFiles(imgUrls)[0]
}

export const getDefaultImgUrl = (imgUrls) => {
  const imgUrList = getImgUrls(imgUrls)
  return imgUrList[0] ? imgUrList[0] : undefined
}

export const getImgUrls = (imgUrls) => {
  if (isEmpty(imgUrls)) return []
  return imgUrls.split(',').map((url) => {
    return getReallyImgUrl(url)
  })
}

// 将数据转化为适合Upload 组件的格式
export const getImgUrlFiles = (imgUrls) => {
  if (isEmpty(imgUrls)) return []
  return imgUrls.split(',').map((url) => {
    return {
      name: url,
      url: getReallyImgUrl(url)
    }
  })
}

// 获取实际的地址
export const getReallyImgUrl = (imgUrl) => {
  // 如果是本地环境，直接获取地址
  // if (process.env.VUE_APP_DEV === 'development') {
  return `${location.protocol}//${location.host}${imgUrl}`
  // } else {
  //   return `${import.meta.env.VUE_APP_BACKEND_IMAGE_IP}${imgUrl}`
  // }
}
