// 主页
import Home from "@/view/index.vue"
// 商品列表
import ProductList from '@/view/productList.vue'
// 商品详情
import ProductDetail from '@/view/productDetail.vue'
// 客户信息
import Customer from '@/view/customer.vue'
// 商品比较
import ProductCompare from '@/view/compare.vue'
// 订单
import Order from '@/view/order.vue'
//登录
import Login from '@/view/login.vue'
//注册
import Register from '@/view/register.vue'
//注册
import ResetPassword from '@/view/resetPassword.vue'
//支持
import Support from '@/view/support.vue'

//订单查询
import OrderQuery from '@/view/orderQuery.vue'

//促销
import Clearance from '@/view/clearance.vue'

//订单跳转
import OrderRedirect from '@/view/orderRedirect.vue'

// 404NotFound
import NotFound from "@/view/404.vue"

import VueRouter from 'vue-router';


const routes = [
  {path: '/', component: Home},
  {path: '/pages/index',  redirect: '/'},
  {path: '/pages/productList', component: ProductList},
  {path: '/pages/productDetail', component: ProductDetail},
  {path: '/pages/my', component: Customer},
  {path: '/pages/compare', component: ProductCompare},
  {path: '/pages/order', component: Order},
  {path: '/pages/login', component: Login},
  {path: '/pages/register', component: Register},
  {path: '/pages/resetPassword', component: ResetPassword},
  {path: '/pages/support', component: Support},
  {
    path: '/pages/supportDetail', redirect: to => {
      // 方法接收目标路由作为参数
      // return 重定向的字符串路径/路径对象
      return {path: '/pages/support', query: {type: to.query?.type}}
    }
  },
  {path: '/pages/orderQuery', component: OrderQuery},
  {path: '/pages/clearance', component: Clearance},
  {path: '/pages/orderRedirect', component: OrderRedirect},
  {path: '/:pathMatch(.*)', component: NotFound}
]


export default routes
