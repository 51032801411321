import request from "@/utils/request";

export const getListByPage = (data) => {
    return request({
        url: "/openapi/customerAddress/getAllByPage",
        data
    })
}

export const getWalletList = (data) => {
    return request({
        url: "/openapi/customerWallet/getAllByPage",
        data
    })
}

export const getWalletLogList = (data) => {
    return request({
        url: "/openapi/walletLog/getAllByPage",
        data
    })
}