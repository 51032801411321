import request from "@/utils/request/index"


// 获取收藏清单（分页）
export const getWishListAllByPage = (data) => {
  return request({
    url: "/openapi/collect/getAllByPage",
    data
  })
}


// 获取收藏清单
export const getWishListAll = (data) => {
  return request({
    url: "/openapi/collect/queryAll",
    data
  })
}

// 添加收藏
export const doAddWishList = (data) => {
  return request({
    url: "/openapi/collect/create",
    data
  })
}

export const doDeleteWishList = (data) => {
  return request({
    url: "/openapi/collect/deleteByProductId",
    data
  })
}
