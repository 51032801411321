import request from "@/utils/request/index"


// 亚马逊支付
export const calculatePrice = (data) => {
  return request({
    url: "/openapi/order/calculatePrice",
    data
  })
}

export const order = (data) => {
  return request({
    url: "/openapi/order/order",
    data
  })
}

export const queryById = (data) => {
  return request({
    url: "/openapi/orderProduct/queryById",
    data
  })
}

export const findByOrderId = (data) => {
  return request({
    url: "/openapi/order/findByOrderId",
    data
  })
}

export const cancelOrder = (data) => {
  return request({
    url: "/openapi/order/cancelOrder",
    data
  })
}

export const refundOrder = (data) => {
  return request({
    url: "/openapi/order/refundOrder",
    data
  })
}