import request from "@/utils/request/index"


// 查询
export const queryAll = (data) => {
  return request({
    url: "/openapi/customerAddress/queryAll",
    data
  })
}




