<template>
	<div class="content-container">
		<!--	路由渲染位置		-->
		<router-view></router-view>
	</div>
</template>

<script>


export default {
	name: 'App',
}
</script>

<style scoped>
@font-face {
  font-family: 'CustomFont';
  src: url('@/assets/font/Roboto-Regular.ttf') format('truetype');
}

body,
::v-deep * {
  font-family: 'CustomFont', Arial, Helvetica, sans-serif;
}

.content-container {
  overflow: hidden; /* 默认情况下隐藏滚动条 */
}

@media screen and (max-width: 1400px) {
  .content-container { /* 注意这里也应使用 .content-container 类名 */
    overflow-x: auto; /* 横向滚动条在需要时出现 */
	white-space: nowrap;
  }
}

</style>
