import request from "@/utils/request";

export const getListByPage = (data) => {
    return request({
        url: "/openapi/customerAddress/getAllByPage",
        data
    })
}

export const doAdd = (data) => {
    return request({
        url: "/openapi/customerAddress/create",
        data
    })
}

export const doEdit = (data) => {
    return request({
        url: "/openapi/customerAddress/update",
        data
    })
}

export const doDelete = (data) => {
    return request({
        url: "/openapi/customerAddress/delete",
        data
    })
}

export const setDefault = (data) => {
    return request({
        url: "/openapi/customerAddress/setDefault",
        data
    })
}