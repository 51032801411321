// 用于缓存用户数据

import {defineStore} from "pinia"
import {ref} from "vue"

const useCustomerStore = defineStore('customer', () => {
  const customerInfo = ref({});

  const updateCustomerInfo = (customer) => {
    customerInfo.value = customer;
  }

  return {customerInfo, updateCustomerInfo}
})

export default useCustomerStore
