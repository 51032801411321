<template>
  <div>
    <el-upload
      v-if="limit"
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      :auto-upload="true"
      :http-request="uploadImage"
      :limit="limit"
      :accept="'image/png,image/jpeg'"
    >
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-upload
      v-else
      action="#"
      list-type="picture-card"
      :file-list="fileList"
      :auto-upload="true"
      :http-request="uploadImage"
      :accept="'image/png,image/jpeg'"
    >
    <el-icon><Plus /></el-icon>
    <template #file="{ file }">
      <div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <!-- <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <el-icon><zoom-in /></el-icon>
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleDownload(file)"
          >
            <el-icon><Download /></el-icon>
          </span> -->
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <el-icon><Delete /></el-icon>
          </span>
        </span>
      </div>
    </template>
      <!-- <i slot="default" class="el-icon-plus"></i> -->
      <!-- <template #default>
        <i class="el-icon-plus"></i>
      </template> -->
     
      <!-- <div slot="file" slot-scope="{ file }"> -->
        <!-- <template v-slot:file="{ file }">
          <i class="el-icon-plus"></i>
          <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-delete" @click="handleRemove(file)">
              <i class="el-icon-delete"></i>
            </span>
          </span>
        </template>
        <el-icon class="avatar-uploader-icon"><Plus /></el-icon> -->
      <!-- </div> -->
    </el-upload>
    <!-- <el-divider content-position="left">
      只能上传jpg或者png格式文件, 商品主图最大数为一
    </el-divider> -->
  </div>
</template>

<script>
  import { doAdd } from '@/api/evaluate/picture'

  export default {
    name: 'ImageUpload',
    props: {
      urls: {
        type: String,
        required: true,
      },
      limit: {
        type: Number,
      },
    },
    data() {
      return {
        // file:{url:null}
      }
    },
    computed: {
      fileList() {
        
        if (this.urls === '') return []
        console.log('urls发生改变', this.urls)
        return this.urls.split(',').map((url) => {
          return {
            url: `${location.protocol}//${location.host}${url}`,
          }
        })
      },
    },

    methods: {
      handleRemove(file) {
        // "http://47.115.204.104//opt/img/1.jpg"
        console.log(file.url, '当前移除的文件路径')

        //  去除被删除的链接
        const newUrls = this.urls
          .split(',')
          .filter((url) => {
            return file.url.indexOf(url) === -1
          })
          .join(',')

        // 提示父组件去更新数据
        this.$emit('update:urls', newUrls)
      },
      async uploadImage(data) {
        const formData = new FormData()
        formData.append('file', data.file)

        const {
          data: { img = '' },
        } = await doAdd(formData)

        console.log(img, '上传文件返回的图片地址')

        let urlList = this.urls !== '' ? this.urls.split(',') : []
        urlList.push(img)

        // 提示父组件去更新数据
        this.$emit('update:urls', urlList.join(','))
      },
    },
  }
</script>
