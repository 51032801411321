<template>
    <pc-header></pc-header>
    <!-- <div style="margin-left: 300px;margin-top: 10px;">
      <router-link to="/">
        <el-image style="width: 160px; height: 80px; margin-top: 20px;margin-left: 10vw;margin-right: 8vw;"
                  :src="logoUrl" fit="fill"/>
      </router-link>
    </div> -->
    <div :style="{  width: '100%' }">
      <el-card style="width: 800px;margin: 60px auto;">
        <div style="font-size: 30px;margin: 30px 330px;font-weight: 600;height: 100px;">Login</div>
        <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="top" style="font-size: 16px;font-weight: 600;">
        
          <el-form-item label="Email Address" prop="userName">
            <el-input v-model="form.userName" placeholder="Please enter your email address" style="height: 50px;" @keydown.enter="handleEnterKey"></el-input>
          </el-form-item>
          <el-form-item label="Password" prop="password">
            <el-input v-model="form.password" type="password" placeholder="Please enter password" style="height: 50px;" @keydown.enter="handleEnterKey"></el-input>
          </el-form-item>
          
        </el-form>
        <div style="text-align: right;">
          <router-link to="/pages/resetPassword">
              <el-link type="danger" style="margin-bottom: 3px;">Forgot Password?</el-link>
            </router-link>
        </div>
        <div style="margin: 50px 30px;">
          <span>Don't have an account?  
            <router-link to="/pages/register">
              <el-link type="danger" style="margin-bottom: 3px;">Sign Up</el-link>
            </router-link>
            here</span>
          <el-button type="primary" style="height: 60px;width: 180px;margin-left: 220px;font-size: 20px;" @click="login">Login</el-button>
        </div>
      </el-card>
    </div>
    
    <pc-footer/>
</template>

<script >
import { ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import { loginByPassword,register,getSms } from "@/api/customer/account.js";
import useCustomerStore from "@/store/customer"
import {useRouter, useRoute} from "vue-router"
import {setToken} from "@/utils/auth";
import useCartCache from "@/store/cart";
import {doAddCart, doDeleteCart, getCartAll,doUpdateCart} from "@/api/spu/cart"

export default {
  name: 'Login',
  components: {
    Delete
  },
  props: {
    msg: String
  },
  data() {
    return {
      form:{
        userName:null,
        password:null,
      },
      backgroundImageUrl: require('@/assets/img/login.png'),
      logoUrl:require('@/assets/img/logo3.gif'),
      router:useRouter(),
      useCustomerStore:useCustomerStore(),
      cartCacheStore : useCartCache(),
      rules: {
        userName: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter password', trigger: 'blur'}
        ],

      },
    }
  },
  methods: {
    async login(){
      try {
        await this.$refs['form'].validate();
        const {data:{token}} = await loginByPassword(this.form)
        setToken(token);
        // 登录成功，保存购物车
        if(token != null){
          const cartList = JSON.parse(localStorage.getItem('cartList'));
          if(cartList && cartList.length > 0){
            cartList.map(item => {
              doAddCart({productId: item.productId,productSpecId: item.productSpecId,num: item.num});
            })
            localStorage.setItem('cartList',"[]")
          }
         
        }
        
        // const {data:customer} = await my({})
        // this.useCustomerStore.updateCustomerInfo(customer);
        // this.router.push({path: '/pages/my'})
        let redirectRoute = this.$route.query.redirect || '/pages/my';
        if(redirectRoute == 'pages/register'){
          redirectRoute = '/pages/my';
        }
        this.$router.push(redirectRoute);
      } catch (err) {
        // 验证失败，err 是包含错误信息的对象
        console.error('表单验证失败：', err);
        return;
      }   
     
    },
    handleEnterKey(event){
      event.preventDefault(); // 阻止默认行为（例如表单提交）
  
      
      this.login()
      
      
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }
</style>
