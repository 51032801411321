// 用于添加比较的缓存
import {defineStore} from "pinia"
import {ref} from "vue"
import useCache from "@/utils/useCache";


const storage = useCache("localStorage")
const useCompareCache = defineStore('compare', () => {
  const compareList = ref([]);

  // 添加比较项目
  // 这里需要处理一下，如果添加的商品已经在比较列表中，则不重复添加
  const addCompareItem = (item) => {
    const existingItem = compareList.value.find((compareItem) => {
      return compareItem.productSpecId === item.productSpecId
    });
    if (existingItem === null) return
    compareList.value.push(item);
  }

  // 删除比较项目
  const deleteCompareItem = (item) => {
    compareList.value = compareList.value.filter((compareItem) => {
      return compareItem.productSpecId !== item.productSpecId
    });
  }

 

  return {compareList, addCompareItem, deleteCompareItem};
})


export default useCompareCache;
