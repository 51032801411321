//  用于缓存一些数据存在浏览器本地，默认为sessionStorage

import { ref} from "vue"
const useCache = (type = 'localStorage') => {
  const storage = ref()

  if (type === 'sessionStorage') {
    storage.value = sessionStorage
  } else {
    storage.value = localStorage
  }

  const set = (key, value) => {
    return storage.value.setItem(key, value)
  }

  const get = (key) => {
    return storage.value.getItem(key)
  }

  const remove = (key) => {
    return storage.value.removeItem(key)
  }

  // storage.value.clear()

  return {
    storage,
    set,
    get,
    remove
  }
}


export default useCache;
