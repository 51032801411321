<template>
  <pc-header/>

    <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
      <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Order Query</div>
      <div> 
        <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span >Order Query</span>
      </div>
    </div>
    <div style="margin:80px auto;width: 1100px;">
        <el-text style="font-size: 20px;margin-right: 30px;">Please input reference number</el-text>
          
          <el-input style="width: 400px;height: 50px;margin-right: 20px;"  v-model="orderId" placeholder="Please input reference number" />
          <el-button type="primary" style="margin-right: 180px;height: 50px;width: 100px;" @click="queryOrder">SUBMIT</el-button>
    </div>
   

    <div v-if="orderDetailData.id" style="width: 1100px;margin:  80px auto;border: 1px solid #000">
      <el-descriptions border :column="2" style="margin-bottom: 30px;">
        <el-descriptions-item label="Reference Number">
          <span>{{ orderDetailData.id }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Pay Type">
          <!-- <span>{{ orderDetailData.payType }}</span> -->
          <el-tag v-if="orderDetailData.payType === 1" type="success">WeChatPay</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 2" type="success">Alipay</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 3" type="success">Balance</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 4" type="success">Credit Card</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 5" type="success">Paypal</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 6" type="success">Google</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 7" type="success">Amazong</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 8" type="success">Check / Money Order</el-tag>
            <el-tag v-else type="success">Unknown</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="Status">
          <el-tag v-if="orderDetailData.status === 1" type="danger">Unpaid</el-tag>
          <el-tag v-else-if="orderDetailData.status === 2" type="success">Paid</el-tag>
          <el-tag v-else-if="orderDetailData.status === 3" type="success">Shipped</el-tag>
          <el-tag v-else-if="orderDetailData.status === 4" type="success">Received</el-tag>
          <el-tag v-else-if="orderDetailData.status === 5" type="info">Finished</el-tag>
          <el-tag v-else-if="orderDetailData.status === 6" type="info">Cancel</el-tag>
          <el-tag v-else-if="orderDetailData.status === 7" type="danger">Refunding</el-tag>
          <el-tag v-else-if="orderDetailData.status === 8" type="info">Refunded</el-tag>
          <el-tag v-else type="info">Unknown</el-tag>
          <!-- <el-tag v-else type="info">系统导入</el-tag> -->
        </el-descriptions-item>
        <el-descriptions-item label="Order Time">
          <span>{{ timeTran(orderDetailData.createTime) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Comments">
          <span>{{ orderDetailData.message }}</span>
        </el-descriptions-item>

      </el-descriptions>

      <el-table :data="orderProductData" style="z-index: 1;">
        <el-table-column property="imgs" label="Image" width="200">
          <template #default="scope">
            <el-image :src="scope.row.imgs.split(',')[0]" fit="cover" style="width: 100px;height: 100px;">
            </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column property="category_name" label="Category" width="150"/> -->
        <el-table-column property="name" label="Name" width="180"/>
        <!-- <el-table-column property="description" label="Description" /> -->

        <!-- <el-table-column property="o_price" label="Market Price"/> -->
        <el-table-column property="price" label="Price" width="120">
          <template #default="scope">
            <span>$ {{ setPrice(scope.row.price) }}</span>
            
          </template>
        </el-table-column>
        <el-table-column property="num" label="Number" width="100"/>
        <el-table-column property="spec" label="Specification" width="300">
          <template #default="scope">
            <span :style="{ whiteSpace: 'pre-wrap' }">{{ setSpec(scope.row.spec) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Operations" width="200">
          <!-- <template #default="scope"> -->
            <!-- <el-button link type="primary" @click="setEvaluate(scope.row)" style="margin-left: 10px;" v-if="orderDetailData.status === 5" >Evaluate</el-button> -->
            <span >Only members can leave a review.</span>
          <!-- </template> -->
        </el-table-column>
      </el-table>

      <el-descriptions border :column="1" style="width: 300px;margin-left: 600px;margin-bottom: 30px;">
        <!-- <el-descriptions-item label="Product Price">
          <span>${{ setPrice(orderDetailData.oPrice) }}</span>
        </el-descriptions-item> -->
        <el-descriptions-item label="Product Price">
          <span>$ {{ setPrice(orderDetailData.price) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Coupon Price" v-if="orderDetailData.dprice">
          <span>$ {{ setPrice(orderDetailData.dprice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Point Price" v-if="orderDetailData.pointPrice">
          <span>$ {{ setPrice(orderDetailData.pointPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Delivery Price" v-if="orderDetailData.deliveryPrice">
          <span>$ {{ setPrice(orderDetailData.deliveryPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Tax Price" v-if="orderDetailData.taxPrice">
          <span>$ {{ setPrice(orderDetailData.taxPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="total Cost">
          <span>$ {{ setPrice(orderDetailData.payPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Refund Price" v-if="orderDetailData.refundPrice">
          <span>$ {{ setPrice(orderDetailData.refundPrice) }}</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions border :column="2" title="Shipping Address">
        <el-descriptions-item label="Delivery No">
          <template v-if="orderDetailData.deliveryId">
            <span>{{ orderDetailData.deliveryId }} (Ship By UPS)</span>
          </template>
          <template v-else>
            <el-tag type="danger">Not Shipped</el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="Phone">
          <span>{{ orderDetailData.phone }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="First Name">
          <span>{{ orderDetailData.firstName }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Last Name">
          <span>{{ orderDetailData.lastName }}</span>
        </el-descriptions-item>
       
        <el-descriptions-item label="Country">
          <span>{{ orderDetailData.country }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="State">
          <span>{{ orderDetailData.province }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="City">
          <span>{{ orderDetailData.city }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="ZIP">
          <span>{{ orderDetailData.postalCode }}</span>
        </el-descriptions-item>

      </el-descriptions>
      <el-button style="margin: 30px 500px;" type="success" @click="openPay" v-if="orderDetailData.status === 1">Pay Now</el-button>
    </div>
    <div v-if="!orderDetailData.id">
      <el-empty description="no data"></el-empty>
    </div>
    <pc-footer/>

     <!-- 支付 -->
  <el-dialog v-model="payVisible" title="Pay Now" width="500" @open="initPayButton" >
    <div v-loading="payLoading" >

      <el-descriptions :column="1" border>
        <el-descriptions-item label="Reference Number">
          {{ orderDetailData.id }}
        </el-descriptions-item>
        <el-descriptions-item label="Grand Total">
        $ {{ setPrice(orderDetailData.payPrice) }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 银行卡 -->
      <div id="checkout-form" style="margin-top: 30px;">
        <!-- <div id="card-name-field-container"></div> -->
        <div  id="card-number-field-container" style="width: 450px;"></div>
        <div  id="card-expiry-field-container" style="width: 225px;float: left;"></div>
        <div  id="card-cvv-field-container" style="width: 225px;float: left;"></div>
        <el-button id="card-field-submit-button" style="width: 450px;height: 50px;" type="primary">
          Pay now with Card Fields
        </el-button> 
      </div>
      <!-- paypal -->
      <div  id="paypal-button-container" style="width: 450px;height: 60px;font-size: 24px;margin-top: 20px;"></div>
      <!-- 线下支付 -->
      <el-button style="width: 450px;height: 50px;margin-top: 10px;" type="success" @click="checkByMail">
        Check / Money Order
      </el-button> 
      <!-- 亚马逊支付 -->
      <div id="AmazonPayButton" ref="amazonPayButton" style="width: 450px;height: 70px;font-size: 24px;margin-top: 20px;" @click="payWithAmazon"></div>  
      <!-- 谷歌支付 -->
      <div  id="googlePayButton" style="width: 450px;height: 50px;font-size: 24px;"></div>

    </div>

     
    

    
    <template #footer>
      <!-- <div class="dialog-footer">
        <el-button @click="payVisible = false">Cancel</el-button>
        <el-button type="primary" @click="setEvaluate">
          Confirm
        </el-button>
      </div> -->
    </template>
  </el-dialog>


</template>

<script >
import { ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import {findByOrderId,queryById} from "@/api/order/order.js";
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import moment from 'moment-timezone';
import { amazonpay,paypalpay,googlepay,cardpay,amazongpayUpdate,offlinepay } from "@/api/order/pay.js";


export default {
  name: 'OrderQuery',
  components: {
    Delete
  },
  props: {
    msg: String
  },
  data() {
    return {
      orderId:null,
      orderDetailData:{},
      orderProductData:[],
      payVisible:false,
      amazonpayButton:null,
      payLoading:false,
    }
    
  },
  created() {
    this.orderId = this.$route.query.orderId;
    if(this.orderId){
      this.queryOrder();
    }
    
  },
  methods: {
    async queryOrder(){
      const {data: order} = await findByOrderId({orderId:this.orderId});
      if(order){
        this.orderDetailData = order;
        const {data: list=[]} = await queryById({conditions: [queryWrapper("orderId", QueryCondition.EQ, order.id)]})
        this.orderProductData = list;
      }else{
        ElMessage({
          message: "not found order",
          type: "error"
        });
      }
      
    },
    timeTran (time) {
      return moment(time).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
    },
    setPrice (price) {
      if(!price) return '0.00';
      return (price/100).toFixed(2);
    },
    setSpec(item) {
      if(item){
        const json = JSON.parse(item);
        let str = '';
        json.forEach((value) => {
          str += value.key + ': ' + value.value + '  \n '
        })
        return str;
      }
      
    },
    openPay () {
      this.payVisible = true;
      
    },

    async initPayButton () {
      console.log("initPayButton")
      await this.initgooglepay();
      await this.initCardFields();
      await this.initPaypal();
      await this.initAmazongpay();
      
    },

    //初始化亚马逊支付按钮
    async initAmazongpay ()  {

      const container = document.getElementById('AmazonPayButton')
      while (container.firstChild) {
          container.removeChild(container.firstChild);
      }
      this.amazonpayButton = await amazon.Pay.renderButton('#AmazonPayButton', {
        merchantId: 'A3C4QCITL0SIUC',
        publicKeyId: 'LIVE-AGSFSLMZZ32L5EORSS66FDR3', 
        ledgerCurrency: 'USD',          
        checkoutLanguage: 'en_US', 
        productType: 'PayOnly', 
        placement: 'Cart',
        buttonColor: 'Gold',
      });
          
      await this.setAmazonPayCSS();
    },

    //延时设置亚马逊支付按钮样式
    async setAmazonPayCSS () {
      setTimeout(() => {
            var view3 =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-view3');
            
            view3[0].style.display = 'none'
            var chevrons =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-chevrons');
            chevrons[0].style.display = 'none'
            var view1 =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-view1');
            view1[0].style.background = '#ffc439'
            view1[0].style.border = '0px solid #ffc439'
          }, 2000); // 1000毫秒即1秒
    },

    //触发亚马逊支付窗口
    async payWithAmazon () {
      this.amazonpayButton.initCheckout({
              
        createCheckoutSession: function(){
          return new Promise((resolve, reject) => {
            setTimeout(() => {

              amazonpay({orderId:this.orderDetailData.id}).then(data =>{
                resolve(data.data.checkoutSessionId);
              })
              
            }, 0); 
          });
        }
      });
    },

    //初始化银行卡支付
    async initCardFields ()  {
      const number = document.getElementById('card-number-field-container')
      while (number.firstChild) {
        number.removeChild(number.firstChild);
      }
      const expire = document.getElementById('card-expiry-field-container')
      while (expire.firstChild) {
        expire.removeChild(expire.firstChild);
      }
      const cvv = document.getElementById('card-cvv-field-container')
      while (cvv.firstChild) {
        cvv.removeChild(cvv.firstChild);
      }

      const cardField =  paypal.CardFields({
          createOrder: function (data) {
            checkPay(this.orderDetailData.id);
            return cardpay({orderId:this.orderDetailData.id}).then((data) => data.data.payId);
        
          },
          onApprove: function (data) {
            console.error('信用卡支付返回', data);
            this.payLoading = false;
            this.$router.push({path:'/pages/orderQuery',query:{orderId:this.orderDetailData.id}});
          },
          onError: function (error) {
            console.error('信用卡支付失败', error);
          },
        });

        
        if (cardField.isEligible()) {

          const numberField = cardField.NumberField();
          console.log("numberField:",numberField);
          numberField.render("#card-number-field-container");

          const cvvField = cardField.CVVField();
          cvvField.render("#card-cvv-field-container");

          const expiryField = cardField.ExpiryField();
          expiryField.render("#card-expiry-field-container");

          document
            .getElementById("card-field-submit-button")
            .addEventListener("click", (data) => {
              console.log("data",data)
              cardField.submit().then(() => {
                // submit successful
              });
            });
        }
    },

    //初始化Paypal支付按钮
    async initPaypal ()  {
      const container = document.getElementById('paypal-button-container')
      while (container.firstChild) {
          container.removeChild(container.firstChild);
      }
      paypal.Buttons({
          style: {
            layout: 'vertical',
            color:  'gold',
            label:  'paypal',
          },
          createOrder: (data, actions) => {
              checkPay(this.orderDetailData.id);
                return paypalpay({orderId:this.orderDetailData.id}).then((data) => data.data.payId);
          },
          onApprove: (data, actions) => {
            this.$router.push({path:'/pages/orderQuery',query:{orderId:this.orderDetailData.id}});
          },
        }).render('#paypal-button-container');
    },

    //初始化googlepay支付按钮
    async initgooglepay  () {
      console.log("initgooglepay")
      try{
        const container = document.getElementById('googlePayButton')
        while (container.firstChild) {
            container.removeChild(container.firstChild);
        }
        const stripe = Stripe('pk_live_51Pq5De0098gZMB2KqUplr6uIX1SH4iSMsW6SNztyNnLrnaerEXOhj52QFRJyDwALa4iOA8cSzZE0BKbGDaBhbcoQ00ZOlWmxCg');
        
        const {data:{payId}} = await googlepay({orderId:this.orderDetailData.id});
        const elements = stripe.elements({clientSecret: payId});

        const expressCheckoutElement = elements.create('expressCheckout',{
          wallets:{
            googlePay:'always'
          }
        });
        expressCheckoutElement.mount('#googlePayButton');

        expressCheckoutElement.on('confirm', async (event) => {

            stripe.confirmPayment({
              elements,
              confirmParams: {
                return_url: 'https://adenamontessori.com/pages/orderQuery?orderId='+this.orderDetailData.id,
              },
            });
          
        })
      } catch(error){
        console.log(error)
      }
    },
    async checkByMail  () {
      await offlinepay({orderId:this.orderDetailData.id});
      payVisible.value = false;
      this.$router.push({path:'/pages/orderQuery',query:{orderId:this.orderDetailData.id}});
    },
    async checkPay(item,payType){
      let intervalId;
      let count = 0;

      // 启动定时器，每隔1000毫秒执行一次
      intervalId = setInterval(async () => {
        count++;
        
        const order = await findByOrderId({ orderId: item });
        
        if (order.data.status == 2 || (count > 60 && payType == 4)) {
          // ElMessage({
          //   message: "Payment successful",
          //   type: "success"
          // });
          

          // 当订单状态变为2时，清除定时器，避免后续无谓的请求
          clearInterval(intervalId);

          
          this.$router.push({path:'/pages/orderQuery',query:{orderId:order.id}});
          
        }
      }, 1000);
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }
</style>
