<template>

  <pc-header></pc-header>

  <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
    <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">My Account</div>
    <div>
      <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span >My Account</span>
    </div>
  </div>

  <el-row :gutter="30" style="margin: auto; margin-top: 60px;width: 1300px;margin-bottom: 60px;">
    <el-col :span="5">
      <el-card style="height: 600px;">
        <template #header>
          
          <div style="display: flex;font-size: 12px;font-weight: 600;width: 90%;">
            <el-avatar :size="60" :src="customerImg" style="background-color: #f8f8f9;width: 80px;"/>
            <span style="width: 70%;word-wrap: break-word;margin-left: 10px;">{{ customer.email }} </span>
          </div>
        </template>

        <div style="margin-bottom: 30px;text-align: left;">
          <el-link @click="changeTabs(1)" style="font-size: 18px;font-weight: 600;" :underline="false">Contact Information</el-link>
        </div>
        <div style="margin-bottom: 30px;text-align: left;">
          <el-link @click="changeTabs(2)" style="font-size: 18px;font-weight: 600;" :underline="false">Address Book</el-link>
        </div>
        <div style="margin-bottom: 30px;text-align: left;">
          <el-link @click="changeTabs(3)" style="font-size: 18px;font-weight: 600;" :underline="false">My Orders</el-link>
        </div>
        <div style="margin-bottom: 20px;text-align: left;">
          <el-link @click="changeTabs(4)" style="font-size: 18px;font-weight: 600;" :underline="false">My Points</el-link>
        </div>
        <el-divider/>
        <div style="margin-bottom: 30px;text-align: left;">
          <el-link @click="openMyCart()" style="font-size: 18px;font-weight: 600;" :underline="false">My Cart</el-link>
        </div>
        <div style="margin-bottom: 30px;text-align: left;">
          <el-link @click="changeTabs(5)" style="font-size: 18px;font-weight: 600;" :underline="false">Wish List</el-link>
        </div>

        <div style="margin-top:100px;margin-bottom: 30px;text-align: left;">
          <el-link @click="logout()" style="font-size: 18px;font-weight: 500;" :underline="false" type="danger">logout</el-link>
        </div>
      </el-card>
    </el-col>

    <el-col :span="19">
      <el-card title="my" style="">

        <template #header>
          <div style="display: flex;font-size: 24px;font-weight: 600;">
            <span>{{ tabName }}</span>
          </div>
        </template>

        <!-- 账号基础信息 -->
        <div v-if="menuNum == 1">
          <div style="margin:auto;width: 840px;">
            <el-button color="#faecd8" style="width: 200px;height: 100px;font-size: 18px;font-weight: 600;" @click="changeTabs(2)">
              Address Book
              <el-icon style="margin-left: 50px;margin-top: 50px;">
                <Right/>
              </el-icon>
            </el-button>
            <el-button color="#faecd8" style="width: 200px;height: 100px;font-size: 18px;font-weight: 600;" @click="changeTabs(3)">
              My Order
              <el-icon style="margin-left: 50px;margin-top: 50px;">
                <Right/>
              </el-icon>
            </el-button>
            <el-button color="#faecd8" style="width: 200px;height: 100px;font-size: 18px;font-weight: 600;" @click="changeTabs(4)">
              My Points
              <el-icon style="margin-left: 50px;margin-top: 50px;">
                <Right/>
              </el-icon>
            </el-button>
            <el-button color="#faecd8" style="width: 200px;height: 100px;font-size: 18px;font-weight: 600;" @click="openMyCart()">
              My Cart
              <el-icon style="margin-left: 50px;margin-top: 50px;">
                <Right/>
              </el-icon>
            </el-button>
          </div>

          <div style="margin-top: 80px;">
            <div style="text-align: right;margin-bottom: 20px;margin-right: 50px;">
              <el-button @click="customeredit" type="primary" icon="Edit">
                <template v-if="isEdit">
                  Edit Profile
                </template>
                <template v-else>
                  Submit
                </template>

              </el-button>
              <el-button type="danger" icon="Lock" @click="passwordVisible = true">Change Password</el-button>
            </div>

            <el-form :model="customer" label-width="150px" style="margin-bottom: 50px;margin-right: 50px;">
              <el-form-item label="Email">
                <!-- <el-input v-model="customer.email" placeholder="Email" size="large"
                          style="margin-left: 10px;height: 60px;" disabled/> -->
                          <span style="margin-left: 10px;font-size: 16px;font-weight: 600;">{{customer.email}}</span>
              </el-form-item>
              <el-form-item label="First Name">
                <el-input v-model="customer.firstName" placeholder="First Name" size="large"
                          style="margin-left: 10px;height: 60px;" :disabled="isEdit"/>
              </el-form-item>
              <el-form-item label="Last Name">
                <el-input v-model="customer.lastName" placeholder="Last Name" size="large"
                          style="margin-left: 10px;height: 60px;" :disabled="isEdit"/>
              </el-form-item>
              <el-form-item label="Phone">
                <el-input v-model="customer.phone" placeholder="" size="large"
                          style="margin-left: 10px;height: 60px;" :disabled="isEdit" maxlength="15"/>
              </el-form-item>
              <el-form-item label="Fax">
                <el-input v-model="customer.fax" placeholder="" size="large"
                          style="margin-left: 10px;height: 60px;" :disabled="isEdit"/>
              </el-form-item>
              <!-- <el-form-item label="Accept email push">
                 <el-switch :model-value="1" :disabled="isEdit" size="large" active-value=1 inactive-value="0" />
              </el-form-item> -->
              <el-form-item label="Country">
                <el-select v-model="customer.country"  placeholder="Select Country" size="large" style="margin-left: 10px;height: 60px;" @change="setStateProvinceByCountry(order.country)" filterable :disabled="isEdit">
                <el-option
                  v-for="item in countryOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.name"
                />
                </el-select>
              </el-form-item>
            </el-form>
          </div>

          <!-- 修改密码 -->
          <el-dialog v-model="passwordVisible" title="Change Password" width="800">
            <el-form :model="passwordForm2" label-width="150px" :rules="passwordRules" ref="passwordForm">
              <el-form-item label="old password"  prop="oldPassword">
                <el-input v-model="passwordForm2.oldPassword" type="password" placeholder="old password" style="height: 50px;"/>
              </el-form-item>
              <el-form-item label="password"  prop="password">
                <el-input v-model="passwordForm2.password" type="password"  placeholder="password" style="height: 50px;" />
              </el-form-item>
              <el-form-item label="confirm password"  prop="confirmPassword">
                <el-input v-model="passwordForm2.confirmPassword" type="password"  placeholder="confirm password" style="height: 50px;" />
              </el-form-item>
            </el-form>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="passwordVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changePassword">
                  Confirm
                </el-button>
              </div>
            </template>
          </el-dialog>
        </div>

        <!-- 地址管理 -->
        <div v-if="menuNum == 2">
          <div style="text-align: right;margin-bottom: 20px;">
            <el-button type="primary" @click="addAddress()">Add New Address</el-button>
          </div>
          <template v-if="addressData.length == 0">
            <el-empty description="No address added yet."></el-empty>
          </template>
          <template v-else>
            <el-table :data="addressData" style="width: 100%" v-loadding="addressLoading" >
              <el-table-column prop="firstName" label="First Name" width="150"/>
              <el-table-column prop="lastName" label="Last Name" width="120"/>
              <el-table-column prop="phone" label="Phone" width="120"/>
              <el-table-column prop="country" label="Country" width="120"/>
              <el-table-column prop="province" label="State" width="120"/>
              <el-table-column prop="city" label="City" width="120"/>
              <el-table-column prop="address" label="Address" width="300"/>
              <el-table-column prop="postalCode" label="ZIP" width="120"/>
              <el-table-column prop="schoolName" label="School Name" width="120"/>
              <el-table-column prop="isDefault" label="isDefault" width="120">
                <template #default="{ row }">
                  <el-tag v-if="row.isDefault === 1">Yes</el-tag>
                  <el-tag v-else>No</el-tag>
                </template>
              </el-table-column>
              <el-table-column fixed="right" label="Operations" width="110">
                <template #default="{ row }">
                  <el-button link type="primary"  @click="editAddressInfo(row)" style="margin-left: 10px;">Edit</el-button>
                  <el-popconfirm title="Set this as the default address?" @Confirm="setDefaultAddress(row.id)" style="width: 300px">
                    <template #reference>
                      <el-button link type="success" v-if="!row.isDefault ||row.isDefault === 0"  style="margin-left: 10px;">Set Default</el-button>  
                    </template>
                  </el-popconfirm>
                  
                  <el-popconfirm title="Are you sure you want to delete this?" @Confirm="deleteAddressInfo(row.id)">
                    <template #reference>
                      <el-button link type="danger"  style="margin-left: 10px;">Delete</el-button>
                    </template>
                    
                  </el-popconfirm>
                  
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-model:current-page="paginationData.page"
              v-model:page-size="paginationData.pageSize"
              :total="paginationData.total"
              @current-change="getCustomerAddressInfo"
              background layout="prev, pager, next"
              style="margin-left: 500px;margin-top: 80px;"/>
          </template>

           

        </div>

        <!-- 我的订单 -->
        <div v-if="menuNum == 3">

          <template v-if="orderData.length == 0">
            <el-empty description="You haven't placed any order yet."></el-empty>
            <div style="margin: auto;width: 150px;margin-bottom: 30px;">
              <router-link to="/pages/productList">
                <el-link type="primary" style="font-size: 20px;font-weight: 600px;">Go Shopping</el-link>
              </router-link>
            </div>
          </template>
          <template v-else>
            <div style="margin: auto;width: 80%;margin-bottom: 30px;">
              <el-alert title="Order Rules"  type="warning" show-icon >
                <span>Shipping Policy: once your order is paid, ship your order promptly. You can track the shipping information in the order details.</span>
                <br>
                <span>Cancellation Policy: Orders will be automatically canceled if payment is not made within 3 hours of creation. Please ensure timely payment to avoid cancellation.</span>
                <br>
                <span>Refund Policy: Upon submitting a refund request, our team will verify the information provided. Once confirmed, funds will be returned to your account using the original payment method.</span>
              </el-alert>
            </div>
            <el-table :data="orderData" style="width: 100%" v-loadding="orderLoadding">
              <el-table-column prop="id" label="Reference Number" width="200"/>
              <el-table-column prop="price" label="Subtotal" width="120">
                <template #default="{ row }">
                  $ {{ setPrice(row.price) }}
                </template>
               
              </el-table-column>
              
              <el-table-column prop="dprice" label="Coupon" width="120">
                <template #default="{ row }">
                  -$ {{ setPrice(row.dprice) }}
                </template>
               
              </el-table-column>
              <el-table-column prop="pointPrice" label="Points" width="130">
                <template #default="{ row }">
                    -$ {{ setPrice(row.pointPrice) }}
                  </template>
                
              </el-table-column>
              <el-table-column prop="deliveryPrice" label="Shipping Fee" width="130">
                <template #default="{ row }">
                    $ {{ setPrice(row.deliveryPrice) }}
                  </template>
                
              </el-table-column>

              <el-table-column prop="payPrice" label="Order Total" width="120">
                <template #default="{ row }">
                  $ {{ setPrice(row.payPrice) }}
                </template>
               
              </el-table-column>
            
              <el-table-column prop="status" label="Status" width="120">
                <template #default="{ row }">
                  <el-tag v-if="row.status === 1" type="danger">Unpaid</el-tag>
                  <el-tag v-else-if="row.status === 2" type="success">Paid</el-tag>
                  <el-tag v-else-if="row.status === 3" type="success">Shipped</el-tag>
                  <!-- <el-tag v-else-if="row.status === 4" type="success">Received</el-tag> -->
                  <el-tag v-else-if="row.status === 5" type="info">Finished</el-tag>
                  <el-tag v-else-if="row.status === 6" type="info">Cancel</el-tag>
                  <el-tag v-else-if="row.status === 7" type="danger">Refunding</el-tag>
                  <el-tag v-else-if="row.status === 8" type="info">Refunded</el-tag>
                  <el-tag v-else type="info">Unknown</el-tag>
                </template>
              </el-table-column>

              
              <el-table-column prop="productNum" label="QTY" width="120"/>
              
              <el-table-column prop="createTime" label="Order Time" width="180">
                <template #default="{ row }">
                  {{ timeTran(row.createTime) }}
                </template>
              </el-table-column>
              <!-- <el-table-column prop="message" label="Comments" width="600"/> -->
              <el-table-column fixed="right" label="Action" width="120">
                <template #default="{row}">
                  <el-button link type="primary" @click="openOrderDetail(row)" style="margin-left: 10px;">Detail</el-button>
                  <el-button link type="success" v-if="row.status === 1" @click="openPay(row)" style="margin-left: 10px;">Pay Now</el-button>
                  <el-popconfirm title="Are you sure to cancel the order?" @Confirm="setCancelOrder(row)" width="200px">
                      <template #reference>
                        <el-button link type="danger" v-if="row.status === 1" style="margin-left: 10px;">Cancel</el-button>
                      </template>
                      
                    </el-popconfirm>
                  
                 
                  <el-popconfirm title="Apply for a refund?" @Confirm="setRefundOrder(row)" width="200px">
                      <template #reference>
                        <el-button link type="danger" v-if="row.status === 2" style="margin-left: 10px;">Refund</el-button>
                      </template>
                      
                    </el-popconfirm>
                  
                  
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              v-model:current-page="paginationData.page"
              v-model:page-size="paginationData.pageSize"
              :total="paginationData.total"
              @current-change="getOrderInfo"
              background layout="prev, pager, next"
              style="margin-left: 500px;margin-top: 80px;"/>
          </template>

        </div>


        <div v-if="menuNum == 4">
          <!-- <template v-if="pointData.length == 0">
            <el-empty description="You haven’t placed any order yet."></el-empty>
          </template> -->
            <!-- <div>{{pointData}}</div> -->
            <div style="margin: auto;width: 80%;margin-bottom: 30px;">
              <el-alert title="Point Rules"  type="warning" show-icon >
                <span>Policy: Earn 1 point for every $1 spent on a purchase.</span>
                <br>
                <span>Every 100 points earned can be redeemed for a $1 discount on future purchases.</span>
              </el-alert>
            </div>
            <div style="margin: auto;width: 1000px;margin-bottom: 30px;font-size: 20px;font-weight: 600;">
              <span>Points Balance:</span>
              <el-tag type="success" size="large" style="margin-left: 20px;font-size: 20px">{{ pointData.walletValue }}</el-tag>
            </div>

            <el-table :data="pointLog" style="width: 100%" v-loadding="pointLoading">
              <el-table-column prop="time" label="Time" width="250">
                <template #default="{ row }">
                  {{ timeTran(row.createTime) }}
                </template>
              </el-table-column>

              <el-table-column prop="Value" label="Value" width="150">
                <template #default="{ row }">
                  <span>
                    <el-tag v-if="row.type === 1" type="success">+ {{ row.walletValue }}</el-tag>
                    <el-tag v-else type="danger">- {{ row.walletValue }}</el-tag>
                  </span>
                  <span>
                   
                  </span>
                </template>
              </el-table-column>
              
              <el-table-column prop="description" label="Description" width="500"/>

            </el-table>

            <el-pagination
              v-model:current-page="paginationData.page"
              v-model:page-size="paginationData.pageSize"
              :total="paginationData.total"
              @current-change="getPointLog"
              background layout="prev, pager, next"
              style="margin-left: 500px;margin-top: 80px;"/>
        </div>

        <!-- 收藏 -->
        <div v-if="menuNum == 5">
          <el-row :gutter="3" style="min-height: 500px;" v-loadding="collectLoading">
            <el-col v-if="productList && productList.length > 0" :xs="8" :sm="8" :md="8" :lg="8" :xl="6" :xxl="6"
                    style="margin-bottom: 20px; cursor: pointer" v-for="item in productList"
                    :key="item">
              <router-link :to="`/pages/productDetail?productId=${item.id}`">
                <el-popover :show-arrow="false" :offset="-270" width="160"  popper-class="cc" placement="top" >
                  <template #reference>
                    <el-card style="width: 260px;border: 0;margin-top: 30px;" shadow="never" :body-style="{ padding: '0px' }">
                      <div style="width: 240px;height: 275px;overflow: hidden;">
                        <el-image :src="item.imgs.split(',')[0]" style="width: 240px;height: 275px;margin-left: 10px;max-width: 100%;max-height: 100%;" fit="cover" class="productImg">
                      
                        </el-image>
                      </div>
                      
                      <div style="text-align: center;margin-top: 10px;">
                        <el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">{{ item.name }}</el-text>
                      </div>
                      <div style="margin-left: 10px;text-align:center">
                        <template v-for="label in labelList" :key="label.id">
                          <el-tag v-if="label.productId === item.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
                        </template>
                      </div>
                      <div style="text-align: center;margin-top: 10px;">
                        <el-text class="mx-1" size="large" style="font-weight: 600;font-size: 16px;">${{
                          setPrice(item.price)
                        }}
                        </el-text>
                        <el-text class="mx-1"  tag="del" style="margin-left: 10px;color: rgb(222, 90, 90);">
                          ${{ setPrice(item.oprice) }}
                        </el-text>
                      </div>
                    </el-card>
                  </template>
                  <template #default>
                    <el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
                      <el-image
                        :src="cartImg"
                        @click="addCart(item)"
                        style="width: 35px;height: 35px;"
                        alt="cart"
                      />
                    </el-button>
                    
                    <el-button text circle style="width: 50px;height: 50px;margin-left: 0;">
                      <el-image
                        :src="compareImg"
                        @click="addCompare(item)"
                        style="width: 30px;height: 30px;"
                        alt="compare"
                      />
                    </el-button>
                    <!-- <el-popconfirm title="Are you sure to delete this?" @Confirm="deleteCollect(item)">
                      <template #reference> -->
                        <el-button text circle style="width: 50px;height: 50px;margin-left: 0;" @click="deleteCollect(item)">
                          <el-image
                            :src="deleteImg"
                            
                            style="width: 30px;height: 30px;"
                            alt="delete"
                          />
                        </el-button>
                      <!-- </template>
                      
                    </el-popconfirm> -->
                    
                  </template>
                </el-popover>
              </router-link>
            </el-col>
            <el-col v-else style="text-align: center; line-height: 500px; font-size: 18px">
              Your wish list is empty
            </el-col>
          </el-row>
          <el-pagination
            v-model:current-page="paginationData.page"
            v-model:page-size="paginationData.pageSize"
            :total="paginationData.total"
            @current-change="getProdcutList"
            background layout="prev, pager, next"
            style="margin-left: 500px;margin-top: 80px;"/>
        </div>

      </el-card>
    </el-col>

  </el-row>

  <!-- 订单详情 -->
  <el-dialog v-model="orderDetailDialog" title="Detail">
    <el-scrollbar height="600px">
      <el-descriptions border :column="2" style="margin-bottom: 30px;">
        <el-descriptions-item label="Reference Number">
          <span>{{ orderDetailData.id }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Payment Method">
          <!-- <span>{{ orderDetailData.payType }}</span> -->
          <el-tag v-if="orderDetailData.payType === 1" type="success">WeChatPay</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 2" type="success">Alipay</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 3" type="success">Balance</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 4" type="success">Credit Card</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 5" type="success">Paypal</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 6" type="success">Google</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 7" type="success">Amazong</el-tag>
            <el-tag v-else-if="orderDetailData.payType === 8" type="success">Check / Money Order</el-tag>
            <el-tag v-else type="success">Unknown</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="Status">
         
          <el-tag v-if="orderDetailData.status === 1" type="danger">Unpaid</el-tag>
          <el-tag v-else-if="orderDetailData.status === 2" type="success">Paid</el-tag>
          <el-tag v-else-if="orderDetailData.status === 3" type="success">Shipped</el-tag>
          <el-tag v-else-if="orderDetailData.status === 4" type="success">Received</el-tag>
          <el-tag v-else-if="orderDetailData.status === 5" type="info">Finished</el-tag>
          <el-tag v-else-if="orderDetailData.status === 6" type="info">Cancel</el-tag>
          <el-tag v-else-if="orderDetailData.status === 7" type="danger">Refunding</el-tag>
          <el-tag v-else-if="orderDetailData.status === 8" type="info">Refunded</el-tag>
          <el-tag v-else type="info">Unknown</el-tag>
          <!-- <el-tag v-else type="info">系统导入</el-tag> -->
        </el-descriptions-item>
        <el-descriptions-item label="Order Time">
          <span>{{ timeTran(orderDetailData.createTime) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Comments">
          <span>{{ orderDetailData.message }}</span>
        </el-descriptions-item>

      </el-descriptions>

      <el-table :data="orderProductData" style="z-index: 1;">
        <el-table-column property="imgs" label="Image" width="200">
          <template #default="scope">
            <el-image :src="scope.row.imgs.split(',')[0]" fit="cover" style="width: 100px;height: 100px;">
            </el-image>
          </template>
        </el-table-column>
        <!-- <el-table-column property="category_name" label="Category" width="150"/> -->
        <el-table-column property="name" label="SKU" width="150"/>
        <!-- <el-table-column property="description" label="Description" /> -->

        <!-- <el-table-column property="o_price" label="Market Price"/> -->
        <el-table-column property="price" label="Unit Price" width="120">
          <template #default="scope">
            <span>$ {{ setPrice(scope.row.price) }}</span>
            
          </template>
        </el-table-column>
        <el-table-column property="num" label="QTY" width="100"/>
        <el-table-column property="price" label="Subtotal" width="120">
          <template #default="scope">
            <span>$ {{ setPrice(scope.row.price*scope.row.num) }}</span>
            
          </template>
        </el-table-column>
        <el-table-column property="spec" label="More Information" width="300">
          <template #default="scope">
            <span :style="{ whiteSpace: 'pre-wrap' }">{{ setSpec(scope.row.spec) }}</span>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="Action" width="200">
          <template #default="scope">
            <el-button link type="primary" @click="setEvaluate(scope.row)" style="margin-left: 10px;" v-if="orderDetailData.status === 5" >Write a review</el-button>
            <span v-else >When the order is completed, you can write a review</span>
          </template>
        </el-table-column>
      </el-table>

      <el-descriptions border :column="1" style="width: 300px;margin-left: 400px;margin-bottom: 30px;">
        <!-- <el-descriptions-item label="Product Price">
          <span>${{ setPrice(orderDetailData.oPrice) }}</span>
        </el-descriptions-item> -->
        <el-descriptions-item label="Subtotal">
          <span>$ {{ setPrice(orderDetailData.price) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Coupon" v-if="orderDetailData.dprice">
          <span>-$ {{ setPrice(orderDetailData.dprice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Points" v-if="orderDetailData.pointPrice">
          <span>-$ {{ setPrice(orderDetailData.pointPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Shipping Fee" v-if="orderDetailData.deliveryPrice">
          <span>$ {{ setPrice(orderDetailData.deliveryPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Tax" v-if="orderDetailData.taxPrice">
          <span>$ {{ setPrice(orderDetailData.taxPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Grand Total">
          <span>$ {{ setPrice(orderDetailData.payPrice) }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Refund" v-if="orderDetailData.refundPrice">
          <span>$ {{ setPrice(orderDetailData.refundPrice) }}</span>
        </el-descriptions-item>
      </el-descriptions>

      <el-descriptions border :column="2" title="Shipping Address">
        <el-descriptions-item label="Tracking#">
          <template v-if="orderDetailData.deliveryId">
            <span>{{ orderDetailData.deliveryId }} (Ship By UPS)</span>
          </template>
          <template v-else>
            <el-tag type="danger">Not Shipped</el-tag>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="Phone">
          <span>{{ orderDetailData.phone }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="First Name">
          <span>{{ orderDetailData.firstName }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Last Name">
          <span>{{ orderDetailData.lastName }}</span>
        </el-descriptions-item>
       
        <el-descriptions-item label="Country">
          <span>{{ orderDetailData.country }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="State">
          <span>{{ orderDetailData.province }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="City">
          <span>{{ orderDetailData.city }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="ZIP">
          <span>{{ orderDetailData.postalCode }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="Street Address" :span="2">
          <span>{{ orderDetailData.address }}</span>
        </el-descriptions-item>
        <el-descriptions-item label="School Name">
          <span>{{ orderDetailData.schoolName }}</span>
        </el-descriptions-item>
      </el-descriptions>
    </el-scrollbar>
    <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="reorder">Reorder</el-button>
          <el-button type="success" @click="Print">Print</el-button>
        </span>
    </template>
  </el-dialog>

  <!-- 评价 -->
  <el-dialog v-model="evaluateVisible" title="Write a review" width="800">
    <el-form :model="rateForm" label-width="150px" :rules="rateRules" ref="rateForm2">
      <el-form-item label="rate"  prop="level">
        <el-rate v-model="rateForm.level"  style="zoom: 1.5;vertical-align: middle;"/>
      </el-form-item>
      <el-form-item label="content"  prop="content">
        <el-input type="textarea" v-model="rateForm.content" maxlength="1000"   style="height: 50px;" />
      </el-form-item>
      <el-form-item label="iamges"  prop="imgs">
        <ImageUpload
          :urls="rateForm.imgs ? rateForm.imgs : ''"
          @update:urls="handleChangeUrls('imgs', $event)"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="evaluateVisible = false">Cancel</el-button>
        <el-button type="primary" @click="addEvaluate">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>

  <!-- 修改/新增地址 -->
  <el-dialog v-model="addressVisible" :title="addressTitle" width="800">
            <el-form :model="addressForm2" label-width="150px" :rules="addressRules" ref="addressForm">
              <el-form-item label="First Name" style="margin-bottom: 20px;" prop="firstName">
                <el-input v-model="addressForm2.firstName"   style="height: 40px;font-size: 16px;" placeholder="Please enter your first name"></el-input>
                
              </el-form-item>
              <el-form-item label="Last Name" style="margin-bottom: 20px;" prop="lastName">
                <el-input v-model="addressForm2.lastName"  style="height: 40px;font-size: 16px;"  placeholder="Please enter your last name"></el-input>
              </el-form-item>
              <el-form-item label="Phone Number" style="margin-bottom: 20px;" prop="phone">
                <el-input v-model="addressForm2.phone"  style="height: 40px;font-size: 16px;" placeholder="Please enter your phone number" maxlength="15"></el-input>
              </el-form-item>
              
                <el-form-item label="Country" style="margin-bottom: 20px;" prop="country">
                  <el-select v-model="addressForm2.country"  placeholder="Select Country" size="large" style="width: 300px;font-size: 16px;" @change="setStateProvinceByCountry(addressForm2.country)" filterable>
                    <el-option
                      v-for="item in countryOptions"
                      :key="item.code"
                      :label="item.name"
                      :value="item.name"
                    />
                  </el-select>
                </el-form-item>
              
              <el-form-item label="State" style="margin-bottom: 20px;" prop="province">
                <el-select v-if="stateOptions.length > 0" v-model="addressForm2.province"  placeholder="Select state" size="large" style="width: 300px;font-size: 16px;" filterable>
                  <el-option
                    v-for="item in stateOptions"
                    :key="item.code"
                    :label="item.name"
                    :value="item.name"
                  />
                
                </el-select>
                <el-input v-else v-model="addressForm2.province" type="text"  style="height: 40px;font-size: 16px;"    placeholder="Please enter the state" ></el-input>
              </el-form-item>
              <el-form-item label="City" style="margin-bottom: 20px;" prop="city">
                <el-input v-model="addressForm2.city" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter the city" ></el-input>
              </el-form-item>
              <el-form-item label="Address" style="margin-bottom: 20px;" prop="address">
                <el-input v-model="addressForm2.address" type="text"  style="height: 40px;font-size: 16px;" placeholder="Please enter the address" ></el-input>
              </el-form-item>
              <el-form-item label="ZIP" style="margin-bottom: 20px;" prop="postalCode">
                <el-input v-model="addressForm2.postalCode" type="text" style="height: 40px;font-size: 16px;"  placeholder="Please enter your postal code"></el-input>
              </el-form-item>
              <el-form-item label="School Name" style="margin-bottom: 20px;" prop="schoolName">
                <el-input v-model="addressForm2.schoolName" type="text" style="height: 40px;font-size: 16px;"  placeholder="Please enter the name of the school"></el-input>
              </el-form-item>
            </el-form>
            <template #footer>
              <div class="dialog-footer">
                <el-button @click="addressVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changeAddress">
                  Confirm
                </el-button>
              </div>
            </template>
          </el-dialog>

  <!-- 支付 -->
  <el-dialog v-model="payVisible" title="Pay Now" width="500" @open="initPayButton" >
    <div v-loading="payLoading" >

      <el-descriptions :column="1" border>
        <el-descriptions-item label="Reference Number">
          {{ currentPayment.id }}
        </el-descriptions-item>
        <el-descriptions-item label="Grand Total">
        $ {{ setPrice(currentPayment.payPrice) }}
        </el-descriptions-item>
      </el-descriptions>
      <!-- 银行卡 -->
      <div id="checkout-form" style="margin-top: 30px;">
        <!-- <div id="card-name-field-container"></div> -->
        <div  id="card-number-field-container" style="width: 450px;"></div>
        <div  id="card-expiry-field-container" style="width: 225px;float: left;"></div>
        <div  id="card-cvv-field-container" style="width: 225px;float: left;"></div>
        <el-button id="card-field-submit-button" style="width: 450px;height: 50px;" type="primary">
          Pay now with Card Fields
        </el-button> 
      </div>
      <!-- paypal -->
      <div  id="paypal-button-container" style="width: 450px;height: 60px;font-size: 24px;margin-top: 20px;"></div>
      <!-- 线下支付 -->
      <el-button style="width: 450px;height: 50px;margin-top: 10px;" type="success" @click="checkByMail">
        Check / Money Order
      </el-button> 
      <!-- 亚马逊支付 -->
      <div id="AmazonPayButton" ref="amazonPayButton" style="width: 450px;height: 70px;font-size: 24px;margin-top: 20px;" @click="payWithAmazon"></div>  
      <!-- 谷歌支付 -->
      <div  id="googlePayButton" style="width: 450px;height: 50px;font-size: 24px;"></div>

    </div>

     
    

    
    <template #footer>
      <!-- <div class="dialog-footer">
        <el-button @click="payVisible = false">Cancel</el-button>
        <el-button type="primary" @click="setEvaluate">
          Confirm
        </el-button>
      </div> -->
    </template>
  </el-dialog>


  <!-- 多个spu类型弹窗	-->
	<el-dialog v-model="dialogVisible" title="Please select product spec"  center align-center style="width: 1000px;">
		
		<div style="margin: auto;padding-top: 30px;border-radius: 1%;display: flex;justify-content: space-between;">
      <el-card style="border: 0px;" shadow="never" v-if="product">
        <el-image
          style="width: 400px; height: 400px;border: 1px solid #000;border-radius: 10px;margin-left: 5px;"
          :src="productImg"
          :zoom-rate="1.2"
          :max-scale="7"
          :min-scale="0.2"
          :preview-src-list="imgList"
          :initial-index="4"
          fit="cover"
        />
        <el-row v-if="product.imgs">
          <el-col :span="4"  v-for="item in product.imgs.split(',')" :key="item">
            <el-link :underline="false">
              <el-image :src="item" style="width: 50px;height: 50px;margin-top: 10px;border: 1px solid #d7d5d5;" @click="chanageImg(item)"></el-image>
            </el-link>
           

            
          </el-col>
        </el-row>
      </el-card>

      <div style="margin-top: 10px;text-align: left;width: 500px;">
        <div style="font-size: 36px;color: #000;font-weight: 600;margin-bottom: 20px;">
          <span>{{product.name}}</span>
          
        </div>
        <div style="margin-top: 20px;">
          <!-- <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 30px;">Market Price: </el-text> -->
          <el-text style="font-size: 30px;color: #000;font-weight: 600;margin-right: 30px;">$ {{setPrice(product.price)}}</el-text>
          <el-text tag="del" style="margin-right: 30px;color: rgb(204, 102, 102);font-weight: 600;font-size: 24px;">$ {{setPrice(product.oprice)}}</el-text>
          
        </div>
        <!-- <div style="margin-bottom: 20px;">
          <el-text style="font-size: 20px;color: #fff;font-weight: 600;margin-right: 55px;">Your Price: </el-text>
         
        </div> -->
        <div style="margin-left: 10px;">
			    <template v-for="label in labelList" :key="label.id">
            <el-tag v-if="label.productId === product.id" type="success" style="margin-right: 10px;margin-top: 10px;">{{ getLabelName(label.labelId) }}</el-tag>
          </template>
        </div>

        <div style="margin-top: 20px;margin-bottom: 20px;">
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Sold: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.soldNum }}</span> </el-text>
          <el-text style="font-size: 16px;color: #000;margin-right: 55px;">Inventory: <span  style="font-size: 20px;color: #000;font-weight: 600;">{{ product.inventory }}</span></el-text>
        </div>

        <div v-if="attr && attr.length > 0" style="margin-top: 50px">
          <div v-for="item in attr" :key="item.key" style="margin-top: 20px">
            <div style="font-size: 20px;color: #000;font-weight: 500;margin-bottom: 10px;"> {{item.key}}</div>
            <el-radio-group v-model="chanceSpec[item.key]" size="large" @change="changeSpec(item.key)" >
              <template v-for="value in item.values" :key="value">
                <el-radio-button :label="value" :value="value" border style="margin-right: 30px;border-radius: 30px;" />
              </template>
              
            </el-radio-group>
          </div>
          
        </div>

       
        <div style="color: #000;margin-bottom: 20px;margin-top: 50px;">
          <el-text style="font-size: 20px;color: #000;font-weight: 600;margin-right: 55px;">Qty: </el-text>
          <el-input-number v-model="productNum" :min="1" :max="product.inventory" :step-strictly="true"  style="height: 50px;font-weight: 600;font-size: 30px;"/>
        </div>
        <div style="font-size: 30px;color: #fff;font-weight: 600;margin-bottom: 20px;margin-top: 20px;width: 380px;">
          

          <el-button round bg type="danger" style="width: 380px;height: 60px;font-size: 18px;font-weight: 600;margin-bottom: 10px;" @click="addCart(product)">
            <el-icon size="30" style="vertical-align: middle;margin-right: 10px;">
              <ShoppingCartFull />
            </el-icon>
            Add to cart
          </el-button>

          <!-- <el-button type="success" text bg round style="width: 170px;height: 50px;font-size: 18px;font-weight: 600;">Add to wish list</el-button>
          
          <el-button type="warning" style="width: 170px;margin-left: 20px;font-weight: 600;font-size: 18px;height: 50px;" text bg round @click="addCompare">Add to compare</el-button> -->
        </div>
      </div>
    </div>
	</el-dialog>

  <pc-footer></pc-footer>
</template>

<script setup>
import {defineOptions, onMounted, reactive, ref, } from 'vue'
import {useRouter, useRoute} from "vue-router"
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import customerImg from '@/assets/img/customer.png'
import * as CustomerApi from "@/api/customer";
import * as CustomerAddressApi from "@/api/customerAddress";
import * as OrderApi from "@/api/order";
import * as productApi from "@/api/spu/index.js";
import useCartCache from "@/store/cart";
import { my,customerEdit } from "@/api/customer/customer.js";
import { myPoints } from "@/api/customer/customerWallet.js";
import { myAccount,updatePassword } from "@/api/customer/account.js";
import {queryById,cancelOrder,refundOrder } from "@/api/order/order.js";
import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import ImageUpload from '@/components/ImageUpload.vue'

import moment from 'moment-timezone';

import { getWishListAll,doDeleteWishList } from "@/api/spu/wishList.js";

import { getProductAllByPage,getSpuAll,getProductLabelAll,getAgeList } from "@/api/spu/index.js";

import cartImg from "@/assets/img/cart.png";
import compareImg from "@/assets/img/compare.png";
import deleteImg from "@/assets/img/delete.png";

import { ElMessage,ElMessageBox,ElLoading   } from 'element-plus'

import { amazonpay,paypalpay,googlepay,cardpay,amazongpayUpdate,offlinepay } from "@/api/order/pay.js";
import {getAccessToken, removeToken} from "@/utils/auth";
import { createEvaluate} from '@/api/spu/evaluate.js';
import { getCountry,getStateProvince} from '@/api/base/index.js';


const cartCacheStore = useCartCache();

defineOptions({
  name: 'customer'
})

const router = useRouter();

const refs = ref();

const customer = ref({})

const currentPayment = ref({})

const menuNum = ref(1)
const tabName = ref("Contact Information")
const isEdit = ref(true)

const passwordVisible = ref(false)
const addressVisible = ref(false)
const evaluateVisible = ref(false)
const payVisible = ref(false)

const dialogVisible = ref(false);
const product = ref({});
const productImg = ref();
const productNum = ref(1);
const chanceSpec = ref({});
const spuList = ref([]);
const attr = ref([{
	key: "颜色",
	value: ["红色", "蓝色", "绿色"]
}, {
	key: "尺寸",
	value: ["S", "M", "L"]
}])

const ageOptions = ref([]);

const labelList = ref([])

const addressTitle = ref("Edit Address");

const addressLoading = ref(false);

const orderLoadding = ref(false);

const pointLoading = ref(false);

const collectLoading = ref(false);

//支付
let amazonpayButton;

//修改密码
const passwordForm2 = ref({
  password: '',
  confirmPassword: '',
  oldPassword: '',
  
})

const passwordForm = ref(null)

//修改/新增地址
const addressForm2 = ref({
  firstName: '1',
  lastName: '',
  Phone: '',
  country: 'United States',
  province: '',
  city: '',
  postalCode: '',
  address: '',
  id: '',
  shcoolName: '',
})

const addressForm = ref(null)

const countryOptions = ref([])

const stateProvinceOptions = ref([])

const stateOptions = ref([])

//评价规则
const rateRules = reactive({
    level: [
      { required: true, message: 'Please enter a rating', trigger: 'blur' },
      { min: 1, max: 5, message: 'The rating must be between 1 and 5', trigger: 'blur' }
    ],
    // comment: [
    //   { required: true, message: '请输入评价', trigger: 'blur' },
    //   { min: 1, max: 200, message: '评价不能超过200字', trigger: 'blur' }
    // ]
  })

//密码规则
const passwordRules = reactive({
    password: [
      { required: true, message: 'Please enter password', trigger: 'blur' },
      { min: 6, message: 'The password cannot be less than 6 digits', trigger: 'blur' }
    ],
    oldPassword: [
      { required: true, message: 'Please enter your old password', trigger: 'blur' },
      { min: 6, message: 'The password cannot be less than 6 digits', trigger: 'blur' }
    ],
    confirmPassword: [
    { required: true, message: 'Please enter a confirmation password', trigger: 'blur' },
      { // 新增的自定义验证规则
        validator: (rule, value, callback) => {
          if (value !== passwordForm2.value.password) {
            callback(new Error('The confirmation password must match the password'));
          } else {
            callback();
          }
        },
        trigger: 'blur' // 可以根据需要选择触发时机：'blur'（失去焦点时）或 'change'（值改变时）
      }
    ]
  })

//地址规则
const addressRules = reactive({
        firstName: [
          { required: true, message: 'Please enter your first name', trigger: 'blur' }
        ],
        lastName: [
          { required: true, message: 'Please enter your last name', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: 'Please enter phone number', trigger: 'blur' }
        ],
        country: [
          { required: true, message: 'Please select a country', trigger: 'change'}
        ],
        province: [
          { required: true, message: 'Please enter state/province', trigger: 'blur'},
          { required: true, message: 'Please select state/province', trigger: 'change'}
        ],
        city: [
          { required: true, message: 'Please enter the city', trigger: 'blur'}
        ],
        address: [
          { required: true, message: 'Please enter the address', trigger: 'blur' }
        ],
        postalCode: [
          { required: true, message: 'Please enter your postal code', trigger: 'blur' }
        ],
        
      })
// 地址
const addressData = ref([])

// 订单
const orderDetailDialog = ref(false)
const orderData = ref([])
const orderDetailData = ref()
const orderProductData = ref([])

const payLoading = ref(false)

// 积分
const pointData = ref([])
const pointLog = ref([])

//评价
const rateForm = ref({
  level: 5,
  content: '',
  imgs: '',
})

//收藏
const wishlistData = ref([])
const productList = ref([])



//分页
// 分页数据
const paginationData = reactive({
	page: 1,
	pageSize: 8,
	total: 0
})


/** 初始化 **/
onMounted(async () => {

  if(!getAccessToken()){
    router.push({path: '/pages/login',query:{
				redirect: router.currentRoute.value.fullPath
			}})
  }

  // const path = router.currentRoute.value.path.split("/");
  // const type = path[path.length - 1];
  const type = router.currentRoute.value.query.type;
  if(type == "order"){
    changeTabs(3)
  }else if(type == "point"){
    changeTabs(4)
  }else if(type == "wishlist"){
    changeTabs(5)
  }else if(type == "address"){
    changeTabs(2)
  }else{
    changeTabs(1)
  }
  
  
  // 获取客户信息
  await getCurrentCustomer();
  
  
})

const getCurrentCustomer = async () => {
  const {data:data} = await my({});
  customer.value = data;
  const {data:account} = await myAccount({});
  customer.value.email = account[0].accountValue;
  return customer.value
}

const openMyCart = () => {
  cartCacheStore.cartDrawer = true
}

const openWishlist = () => {
  console.log("openWishlist")
}

const timeTran = (time) => {
  return moment(time).tz('America/New_York').format('YYYY-MM-DD HH:mm:ss');
}



/**
 * 切换 tabs 页面
 * @param tabId
 */
const changeTabs = (tabId) => {
  menuNum.value = tabId;
  paginationData.page = 1;
  paginationData.pageSize = 8;

  if (tabId === 1) {
    tabName.value = "Contact Information";
    getC();
  }

  if (tabId === 2) {
    tabName.value = "Address Book";
    getCustomerAddressInfo();
    getC();
    getSP();
  }

  if (tabId === 3) {
    tabName.value = "My Orders";
    getOrderInfo();
  }

  if (tabId === 4) {
    tabName.value = "My Points"
    getPointInfo();
  }

  if (tabId === 5) {
    tabName.value = "Wish List"
    getWishlist();
    getAgeLabel();
  }
  
}

/** 地址相关 **/
const getCustomerAddressInfo = async () => {
  addressLoading.value = true;
  const {data: {list: addressList, pageNum, total}} = await CustomerAddressApi.getListByPage({
    page: paginationData.page,
    pageSize: paginationData.pageSize,
    conditions: [queryWrapper("create_time", QueryCondition.DESC, "")]
  })

  addressData.value = addressList;
  paginationData.page = pageNum;
  paginationData.total = total;
  addressLoading.value = false;
}

const editAddressInfo = (address) => {
  addressVisible.value = true;
  setStateProvinceByCountry(address.country)
  addressForm2.value = address; 
  
  addressTitle.value = "Edit Address";

  console.log("editAddressInfo", addressForm2)
}

const deleteAddressInfo = async (addressId) => {
  await CustomerAddressApi.doDelete({customerAddressId:addressId});
  getCustomerAddressInfo(); 
  console.log("deleteAddressInfo", addressId)
}

const addAddress = () => {
  addressVisible.value = true;
  addressForm2.value = {
    country: "United States",
  }
  setStateProvinceByCountry("United States")
  addressTitle.value = "Add Address";
  console.log("addAddress")
}

const changeAddress = async () => {
  
  addressVisible.value = false;
  try {
      await addressForm.value.validate();

      if(addressTitle.value == "Edit Address"){
        await CustomerAddressApi.doEdit(addressForm2.value);
      }else{
        await CustomerAddressApi.doAdd(addressForm2.value);
      }
      getCustomerAddressInfo(); 
      
  } catch (err) {
    // 验证失败，err 是包含错误信息的对象
    console.error('表单验证失败：', err);
    return;
  }   
    
  
}

//设置默认地址

const setDefaultAddress = async (addressId) => {
    await CustomerAddressApi.setDefault({customerAddressId:addressId});
    getCustomerAddressInfo(); 
    
  
}

//获取国家
const getC = async () => {
  const {data:  countryList} = await getCountry({})
  countryOptions.value = countryList;

}
//获取省/州
const getSP = async () => {
  const {data:  stateProvinceList} = await getStateProvince({})
  stateProvinceOptions.value = stateProvinceList;
}

//通过国家筛选州/省
const setStateProvinceByCountry = async (countryId) => {
  if(countryId){
    addressForm2.value.province = null;
    const country = countryOptions.value.filter(item => item.name == countryId);
    stateOptions.value = stateProvinceOptions.value.filter(item => item.country == country[0].code);
  }
}

//退出登录
const logout = () => {
  removeToken();
  router.push({path: '/'})
}

const customeredit = async () => {
  if(!isEdit.value){
    await customerEdit(customer.value);
    await getCurrentCustomer();
  }

  isEdit.value = !isEdit.value
}



/** 订单相关 **/
const getOrderInfo = async () => {
  orderLoadding.value = true;
  const {data: {list: orderList,total,pageNum}} = await OrderApi.getListByPage({
    page: paginationData.page,
    pageSize: paginationData.pageSize,
    conditions: [queryWrapper("create_time", QueryCondition.DESC, "")]
  })
  paginationData.total = total;
  paginationData.page = pageNum;
  orderData.value = orderList;
  orderLoadding.value = false;
}

const openOrderDetail = async (order) => {
  // 获取订单详细信息
  const {data: list=[]} = await queryById({conditions: [queryWrapper("orderId", QueryCondition.EQ, order.id)]})
  orderDetailData.value = order;
  orderProductData.value = list;

  orderDetailDialog.value = true
}

const openPay = async (order) => {
  currentPayment.value = order;
  payVisible.value = true;
  
}

const initPayButton = async () => {
  console.log("initPayButton")
  await initgooglepay();
  await initCardFields();
  await initPaypal();
  await initAmazongpay();
  
}

//初始化亚马逊支付按钮
const initAmazongpay = async () => {

  const container = document.getElementById('AmazonPayButton')
  while (container.firstChild) {
      container.removeChild(container.firstChild);
  }
  amazonpayButton = await amazon.Pay.renderButton('#AmazonPayButton', {
    merchantId: 'A3C4QCITL0SIUC',
    publicKeyId: 'LIVE-AGSFSLMZZ32L5EORSS66FDR3', 
    ledgerCurrency: 'USD',          
    checkoutLanguage: 'en_US', 
    productType: 'PayOnly', 
    placement: 'Cart',
    buttonColor: 'Gold',
  });
      
  await setAmazonPayCSS();
}

//延时设置亚马逊支付按钮样式
const setAmazonPayCSS = async () => {
  setTimeout(() => {
        var view3 =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-view3');
        
        view3[0].style.display = 'none'
        var chevrons =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-chevrons');
        chevrons[0].style.display = 'none'
        var view1 =  document.getElementById('AmazonPayButton').shadowRoot.querySelectorAll('.amazonpay-button-view1');
        view1[0].style.background = '#ffc439'
        view1[0].style.border = '0px solid #ffc439'
      }, 2000); // 1000毫秒即1秒
}

//触发亚马逊支付窗口
const payWithAmazon = async () => {
  amazonpayButton.initCheckout({
          
    createCheckoutSession: function(){
      return new Promise((resolve, reject) => {
        setTimeout(() => {

          amazonpay({orderId:currentPayment.value.id}).then(data =>{
            resolve(data.data.checkoutSessionId);
          })
          
        }, 0); 
      });
    }
  });
}

//初始化银行卡支付
const initCardFields = async () => {
  const number = document.getElementById('card-number-field-container')
  while (number.firstChild) {
    number.removeChild(number.firstChild);
  }
  const expire = document.getElementById('card-expiry-field-container')
  while (expire.firstChild) {
    expire.removeChild(expire.firstChild);
  }
  const cvv = document.getElementById('card-cvv-field-container')
  while (cvv.firstChild) {
    cvv.removeChild(cvv.firstChild);
  }

  const cardField =  paypal.CardFields({
      createOrder: function (data) {
        checkPay(currentPayment.value.id);
        return cardpay({orderId:currentPayment.value.id}).then((data) => data.data.payId);
    
      },
      onApprove: function (data) {
        console.error('信用卡支付返回', data);
        payLoading.value = false;
        router.push({ path: '/pages/my?type=order' });
      },
      onError: function (error) {
        console.error('信用卡支付失败', error);
      },
    });

    
    if (cardField.isEligible()) {

      const numberField = cardField.NumberField();
      console.log("numberField:",numberField);
      numberField.render("#card-number-field-container");

      const cvvField = cardField.CVVField();
      cvvField.render("#card-cvv-field-container");

      const expiryField = cardField.ExpiryField();
      expiryField.render("#card-expiry-field-container");

      document
        .getElementById("card-field-submit-button")
        .addEventListener("click", (data) => {
          console.log("data",data)
          cardField.submit().then(() => {
            // submit successful
          });
        });
    }
}

//初始化Paypal支付按钮
const initPaypal = async () => {
  const container = document.getElementById('paypal-button-container')
  while (container.firstChild) {
      container.removeChild(container.firstChild);
  }
  paypal.Buttons({
      style: {
        layout: 'vertical',
        color:  'gold',
        label:  'paypal',
      },
      createOrder: (data, actions) => {
          checkPay(currentPayment.value.id);
            return paypalpay({orderId:currentPayment.value.id}).then((data) => data.data.payId);
      },
      onApprove: (data, actions) => {
        router.push({ path: '/pages/my?type=order' });
      },
    }).render('#paypal-button-container');
}

//初始化googlepay支付按钮
const initgooglepay = async () => {
  console.log("initgooglepay")
  try{
    const container = document.getElementById('googlePayButton')
    while (container.firstChild) {
        container.removeChild(container.firstChild);
    }
    const stripe = Stripe('pk_live_51Pq5De0098gZMB2KqUplr6uIX1SH4iSMsW6SNztyNnLrnaerEXOhj52QFRJyDwALa4iOA8cSzZE0BKbGDaBhbcoQ00ZOlWmxCg');
    
    const {data:{payId}} = await googlepay({orderId:currentPayment.value.id});
    const elements = stripe.elements({clientSecret: payId});

    const expressCheckoutElement = elements.create('expressCheckout',{
      wallets:{
        googlePay:'always'
      }
    });
    expressCheckoutElement.mount('#googlePayButton');

    expressCheckoutElement.on('confirm', async (event) => {

        stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: 'https://adenamontessori.com/pages/my?type=order',
          },
        });
      
    })
  } catch(error){
    console.log(error)
  }
}

const checkByMail = async () => {
  await offlinepay({orderId:currentPayment.value.id});
  payVisible.value = false;
  router.push({ path: '/pages/my?typeorder' });
}

const setRefundOrder = async (order)=> {
  await refundOrder({orderId:order.id});
  console.log("Refund", order)
  getOrderInfo()
}

const setCancelOrder = async (order)=> {
  await cancelOrder({orderId:order.id});
  console.log("Refund", order)
  getOrderInfo()
}

const setPrice = (price) => {
  if(!price) return '0.00';
  return (price/100).toFixed(2);
}

/**  积分相关  **/
const getPointInfo = async () => {
  const {data: point} =  await myPoints({})
  pointData.value = point;
  getPointLog();
 
}

const getPointLog =  async () => {
  pointLoading.value = true;
  const {data: {list: pointLogList, total, pageNum}} =  await CustomerApi.getWalletLogList({
    page: paginationData.page,
    pageSize: paginationData.pageSize,
    conditions: [queryWrapper("create_time", QueryCondition.DESC, "")]
  })
  pointLog.value = pointLogList;
  paginationData.total = total;
  paginationData.page = pageNum;
  pointLoading.value = false;
}

//评价
const setEvaluate =  (product) => {
  rateForm.value ={
    productId: product.productId,
    opId:product.productSpecId,
    orderId: product.orderId,
    productName: product.productName,
    status:1,
    level: 5,
    content: '',
    imgs: '',
  }
  

  evaluateVisible.value = true;
}

const addEvaluate = async () => {2
  // let ratedata = {
  //   productId: rateForm.value.productId,
  //   content: rateForm.value.content,
  //   level: rateForm.value.level,
  //   imgs: rateForm.value.imgs,
  //   opId:rateForm.value.productSpecId,
  //   orderId:rateForm.value.orderId,
  //   productName:rateForm.productName
  // }
 await createEvaluate(rateForm.value);
 ElMessage({
          message: "Successfully added comment",
          type: "success"
        });
 evaluateVisible.value = false;
}

const handleChangeUrls = (field, value) => {
  rateForm.value = { ...rateForm.value, [field]: value }
}

//收藏
const getWishlist = async () => {
  collectLoading.value = true;
  const {data:  wishList} =  await getWishListAll({conditions:[queryWrapper("create_time", QueryCondition.DESC, "")]})
  wishlistData.value = wishList;
  getProdcutList();
  collectLoading.value = false;
}

const getProdcutList = async () => {
  if(wishlistData.value.length >0){
    var productIds = [];
    for(var i = 0; i < wishlistData.value.length; i++){
      productIds.push(wishlistData.value[i].productId);
    }
    const {data: {list: pList, total, pageNum}} =  await getProductAllByPage({
      page: paginationData.page,
      pageSize: paginationData.pageSize,
      conditions: [queryWrapper("id", QueryCondition.IN, productIds)]
    })
    productList.value = pList;
    paginationData.total = total;
    paginationData.page = pageNum;

    if(pList && pList.length > 0){
		let array = [];
		for (let i = 0; i < productList.value.length; i++) {
			array.push(productList.value[i].id)
		}
		//查询商品标签
		const {data:  labels} = await getProductLabelAll({
			conditions: [queryWrapper("productId", QueryCondition.IN, array)]
		})
		labelList.value = labels;
	}
  }else{
    productList.value = [];
  }
  
}

// 获取年龄标签
const getAgeLabel = async () => {
	const {data: list} = await getAgeList({
		
		conditions: []
	})
	ageOptions.value = list;
	console.log(list, "年龄分类数据");
}

const getLabelName = (id) =>{
	for (let i = 0; i < ageOptions.value.length; i++) {
		if(ageOptions.value[i].id == id){
			return ageOptions.value[i].name
		}
	}
}

// 处理添加购物车
const addCart = async (item) => {
	if(!dialogVisible.value){
		item.num = 1;
		const dialogVisible = await checkOpenDialog(item)
		// 如果打开弹窗直接返回
		if (dialogVisible) return;
		
    if(spuList.value[0].inventory < item.num){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}
		
		// 未打开直接添加购物车，并写入productSpecId
		const specDesc = spuList.value[0].specDesc ? JSON.stringify(spuList.value[0].specDesc) : [];
		await cartCacheStore.addCartItem({...item, productSpecId: spuList.value[0].id, specDesc: specDesc})
		cartCacheStore.cartDrawer = true
	}else{
		product.value.num = productNum.value
		let spec ={}
		let array = []
		for (const [key, value] of Object.entries(chanceSpec.value)) {
			const s = {
			key: key,
			value: value
			};
			array.push(s);
		}
		spuList.value.forEach((value) => {
			
			if(value.specDesc == JSON.stringify(array)){
			
			spec = value;
			return;
			}
		});
    if(!spec.id || spec.inventory < productNum.value){
			ElMessage({
			message: "Out of stock",
			type: "error"
			});
			return;
		}
		await cartCacheStore.addCartItem({...product.value, productSpecId: spec.id, specDesc: array})
		cartCacheStore.cartDrawer = true
	}
  ElMessage({
          message: "Successfully added to shopping cart",
          type: "success"
        });
	//删除收藏
  await doDeleteWishList({productId: item.id})
  getWishlist();
	openMyCart();
  dialogVisible.value = false
}

// 用于判断是否需要打开弹窗(判断商品对应的spu是否存在多条)
const checkOpenDialog = async (item) => {

product.value = item;
productImg.value = item.imgs.split(',')[0]
const {data = []} = await getSpuAll({
  conditions: [queryWrapper("productId", QueryCondition.EQ, item.id),queryWrapper("sort", QueryCondition.DESC, "")]
});
spuList.value = data;
if (data && data.length > 1) {
  dialogVisible.value = true;
  // specTypeList.value = convertSpec(data);
  attr.value = JSON.parse(item.attr);

    const specDesc = JSON.parse(data[0].specDesc);
    for(let i = 0; i < specDesc.length; i++){
      const spec = specDesc[i];
      chanceSpec.value[spec.key] = spec.value;
    }

    product.value.soldNum = data[0].soldNum;
    product.value.inventory = data[0].inventory;
    product.value.oprice = data[0].oprice;
    product.value.price = data[0].price;
  return true;
}
return false
}

// 处理添加比较
const addCompare = (item) => {
	//compareCacheStore.addCompareItem(item)
	var compareList = JSON.parse(localStorage.getItem("compareList")) || [{}];
	var flag = true

	
	for(let i = 0; i < compareList.length; i++){
		if(compareList[i].id === item.id){
			flag = false;
			break;
		}
	}
	
	if(flag){
		
		compareList.push(item);
		localStorage.setItem("compareList", JSON.stringify(compareList));
		ElMessageBox.confirm(
			'Would you like to check the comparison list?',
			'Success!',
			{
			confirmButtonText: 'OK',
			cancelButtonText: 'Cancel',
			type: 'success',
			}
		).then(() => {
			router.push({path: '/compare'})
		})
		.catch(() => {
		
		})
	}else{
		
		ElMessage({
			message: "This product is already in the comparison list.",
			type: "warning"
		});
	}
}

const deleteCollect = async (item) => {
  //删除收藏
  await doDeleteWishList({productId: item.id})
  getWishlist();
}

//修改密码
const changePassword = async () => {
  
  try{
    await passwordForm.value.validate();
    await updatePassword({type: 3,oldPassword:passwordForm2.value.oldPassword, password: passwordForm2.value.password})
    logout();
    ElMessage({
      message: "Modified successfully",
      type: "success"
    });

  }catch(error){

  }
  await doDeleteWishList({productId: item.id})
  getWishlist();
}

const chanageImg = (item) =>{
      productImg.value = item
 }

 const changeSpec = () =>{
      let array = []
     
      for (const [key, value] of Object.entries(chanceSpec.value)) {
        const s = {
          key: key,
          value: value
        };
        array.push(s);
      }
      product.value.soldNum = 0;
          product.value.inventory = 0;
          product.value.oprice = 0;
          product.value.price = 0;
      spuList.value.forEach((value) => {
        
        if(value.specDesc == JSON.stringify(array)){
          
          product.value.soldNum = value.soldNum;
          product.value.inventory = value.inventory;
          product.value.oprice = value.oprice;
          product.value.price = value.price;
          return;
        }
      });
    }

    const setSpec = (item) =>{
      if(item){
        const json = JSON.parse(item);
        let str = '';
        json.forEach((value) => {
          str += value.key + ': ' + value.value + '  \n '
        })
        return str;
      }
      
    }

    //检查支付状态
    const checkPay = async (item) => {
      payLoading.value = true;
      let intervalId;
      let count = 0;
      // 启动定时器，每隔1000毫秒执行一次
      intervalId = setInterval(async () => {
        const order = await OrderApi.findByOrderId({ orderId: item });

        if (order.data.status == 2 || count > 60) {
          ElMessage({
            message: "Payment successful",
            type: "success"
          });
          payVisible.value = false;
          payLoading.value = false;
          changeTabs(3);

          // 当订单状态变为2时，清除定时器，避免后续无谓的请求
          clearInterval(intervalId);
        }
        count++;
      }, 1000);

    }

    //打印
    const Print = () => {
      window.print();

    }

    const reorder = () => {
      orderProductData.value = orderProductData.value.map(item => {
        return {
          ...item,
          specDesc: item.spec,
          
        }
      })
      localStorage.setItem("products",JSON.stringify(orderProductData.value));
      router.push({path: '/pages/order'})
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.el-popper.is-dark2 {
  background: #e35959;
  border: 1px solid #e35959;
  margin-left: 10px;
  vertical-align: middle;
  width: 22px;
  height: 12px;
}

.el-popper.is-dark2 .el-popper__arrow::before {
  background: #e35959;
  border: 1px solid #e35959;
}

.el-popover.el-popover.cc {
  padding: 0;
}

.el-tabs__item {
  font-size: 24px !important;
}

#tab-first {
  margin-bottom: 10px;
}

.productImg:hover {
	transform: scale(1.2);
}

.productImg {
	transition: transform 0.3s ease-in-out;
	
}
</style>
