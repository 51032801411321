import request from "@/utils/request/index"


export const myAccount = (data) => {
  return request({
    url: "/openapi/account/my",
    data
  })
}

// 登录
export const loginByPassword = (data) => {
  return request({
    url: "/openapi/account/loginByPassword",
    data
  })
}

// 注册
export const register = (data) => {
  return request({
    url: "/openapi/account/register",
    data
  })
}

// 获取验证码
export const getSms = (data) => {
  return request({
    url: "/openapi/account/getSms",
    data,
    timeout:0
  })
}

// 重置密码
export const resetPassword = (data) => {
  return request({
    url: "/openapi/account/resetPassword",
    data
  })
}

export const updatePassword = (data) => {
  return request({
    url: "/openapi/account/updatePassword",
    data
  })
}


