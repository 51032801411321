import request from "@/utils/request";

export const getVisits = (data) => {
    return request({
        url: "/openapi/base/getVisits",
        data
    })
}

export const getCountry = (data) => {
    return request({
        url: "/openapi/base/getCountry",
        data
    })
}

export const getStateProvince = (data) => {
    return request({
        url: "/openapi/base/getStateProvince",
        data
    })
}
