<template>
	<pc-header></pc-header>
	<!-- <div style="margin-left: 300px;margin-top: 10px;">
		<router-link to="/">
			<el-image style="width: 160px; height: 80px; margin-top: 20px;margin-left: 10vw;margin-right: 8vw;"
								:src="logoUrl" fit="fill"/>
		</router-link>
	</div> -->
	<el-card class="register-container" style="width: 800px;margin: 60px auto;">
		<div style="font-size: 30px;margin: 30px auto;font-weight: 600;height: 100px;width: 500px;">Create New Customer Account</div>
		<el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="top"
		         style="font-size: 16px;font-weight: 600;">
			<el-form-item label="First Name" prop="firstName" size="Large">
				<el-input v-model="form.firstName" placeholder="Please enter your first name" style="height: 50px;"></el-input>
			</el-form-item>
			<el-form-item label="Last Name" prop="lastName">
				<el-input v-model="form.lastName" placeholder="Please enter your last name" style="height: 50px;"></el-input>
			</el-form-item>
			<el-form-item label="Verify your email address" prop="userName">
				<el-input v-model="form.userName" placeholder="Please enter your email address" style="height: 50px;">
					
				</el-input>
			</el-form-item>
			<el-form-item label="Verify Your Email Address" prop="sms">
				<el-button type="primary" @click="sendEmail" style="width:150px;height: 50px;margin-right:10px;" :loading="sendEmailLoading"
                      :disabled="!form.userName || sendEmailLoading || countDownNum > 0">
					{{ countDownNum > 0 ? `${countDownNum}s try again` : 'Send verification code' }}
				</el-button>

				<el-input v-model="form.sms" placeholder="Please enter your received code" style="height: 50px;width: 300px;">

				</el-input>

				<div v-if="countDownNum > 0">
					An email will be sent to your email address.If you don't receive the email within a few minutes, check your Spam/Junk folder.
				</div>
			
			</el-form-item>
			<el-form-item label="Password" prop="password">
				<el-input v-model="form.password" type="password" placeholder="Please enter password"
				          style="height: 50px;"></el-input>
			</el-form-item>
			<el-form-item label="Confirm Password" prop="confirmPassword">
				<el-input v-model="form.confirmPassword" type="password" placeholder="Please enter your confirm password"
				          style="height: 50px;"></el-input>
			</el-form-item>

			<el-form-item label="Country" style="margin-bottom: 20px;" prop="country">
				<el-select v-model="form.country"  placeholder="Select Country" size="large" style="width: 300px;font-size: 16px;" @change="setStateProvinceByCountry(order.country)" filterable>
				<el-option
					v-for="item in countryOptions"
					:key="item.name"
					:label="item.name"
					:value="item.name"
				/>
				</el-select>
            </el-form-item>

		    <el-form-item label="Sign Up for Newsletter" prop="emailsu">
				<el-switch
					v-model="form.emailsu"
					size="large"
					active-text="Yes"
					inactive-text="No"
					active-value="1"
					inactive-value="0"
				/>
			</el-form-item>
		</el-form>
		
		
		<div style="margin: 50px 30px;">
        <span>Already have an account?  
          <router-link to="/pages/login">
          <el-link type="danger" style="margin-bottom: 3px;">Login</el-link>
          </router-link>
           here</span>
			<el-button type="primary" style="height: 60px;width: 180px;margin-left: 220px;font-size: 20px;" @click="register">
				Create an Account
			</el-button>
		</div>
	</el-card>
	
	<pc-footer/>
</template>

<script>
import {ref} from 'vue'
import {Delete} from '@element-plus/icons-vue'
import {my, loginByPassword, register, getSms} from "@/api/customer/account.js";
import useCustomerStore from "@/store/customer"
import {useRouter, useRoute} from "vue-router"
import {setToken} from "@/utils/auth";
import {ElMessage} from "element-plus";
import { getCountry,getStateProvince } from "@/api/base/index.js";
import useCartCache from "@/store/cart";
import {doAddCart, doDeleteCart, getCartAll,doUpdateCart} from "@/api/spu/cart"

export default {
	name: 'Register',
	components: {
		Delete
	},
	props: {
		msg: String
	},
	data() {
		return {
			form: {
				firstName: null,
				lastName: null,
				userName: null,
				sms: null,
				password: null,
				confirmPassword: null,
				type: 3,
				country:'United States'
			},
			logoUrl: require('@/assets/img/logo3.gif'),
			useCustomerStore: useCustomerStore(),
			router: useRouter(),
			cartCacheStore : useCartCache(),
			countryOptions:[],
			rules: {
				firstName: [
					{required: true, message: 'Please enter your first name', trigger: 'blur'}
				],
				lastName: [
					{required: true, message: 'Please enter your last name', trigger: 'blur'}
				],
				userName: [
					{required: true, message: 'Please enter your email address', trigger: 'blur'}
				],
				sms: [
					{required: true, message: 'Please enter your received code', trigger: 'blur'},
				],
				password: [
					{required: true, message: 'Please enter password', trigger: 'blur'},
					{min: 6, message: 'The password cannot be less than 6 digits', trigger: 'blur'}
				],
				confirmPassword: [
					{required: true, message: 'Please enter your confirm password', trigger: 'blur'},
					{ // 新增的自定义验证规则
						validator: (rule, value, callback) => {
							if (value !== this.form.password) {
								callback(new Error('The confirmation password must match the password'));
							} else {
								callback();
							}
						},
						trigger: 'blur' // 可以根据需要选择触发时机：'blur'（失去焦点时）或 'change'（值改变时）
					}
				],
			},
			countDownNum: 0,
			countDownTimer: null,
			sendEmailLoading: false,
		}
	},
	async created  () {
		const {data:country} = await getCountry({});
    	this.countryOptions = country;
	},
	methods: {
		async register() {
			try {
				await this.$refs['form'].validate();
				const {data: token} = await register(this.form);
				setToken(token.token);
				
				// 登录成功，保存购物车
				if(token != null){
					const cartList = JSON.parse(localStorage.getItem('cartList'));
					if(cartList && cartList.length > 0){
						cartList.map(item => {
							doAddCart({productId: item.productId,productSpecId: item.productSpecId,num: item.num});
						})
						localStorage.setItem('cartList',"[]")
					}
					
				}
		
				
				// const {data:customer} = await my({})
				// this.useCustomerStore.updateCustomerInfo(customer);
				ElMessage({
					message: "Successfully registered",
					type: "success"
				});
				setTimeout(() => {
					 this.router.push({path: '/pages/my'})
				}, 3000)
			} catch (err) {
				// 验证失败，err 是包含错误信息的对象
				console.error('表单验证失败：', err);
				return;
			}
		},
		// 发送邮箱验证码
		async sendEmail() {
			if (!this.form.userName) return
			this.sendEmailLoading = true
			try {
				
				await getSms({
					phone: this.form.userName,
					accountType: 3,
					smsType: 2
				})
				this.sendEmailLoading = false
				this.countDown(); // 触发倒计时
				ElMessage({
					message: "Successfully send  email",
					type: "success"
				});
			} catch (err) {
			} finally {
				this.sendEmailLoading = false
			}
		},
		// 验证码倒计时倒计时
		countDown() {
			this.countDownNum = 30;
			this.countDownTimer = setInterval(() => {
				this.countDownNum = this.countDownNum - 1;
				if (this.countDownNum <= 0) clearInterval(this.countDownTimer)
			}, 1000)
		}
	},
	beforeDestroy() {
		clearInterval(this.countDownTimer)
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.register-container :deep(.el-input-group__append button.el-button) {
	color: var(--el-button-hover-text-color);
	border-color: var(--el-button-hover-border-color);
	background-color: var(--el-button-bg-color);
}

.register-container :deep(.el-input-group__append button.el-button:hover) {
	color: var(--el-button-hover-text-color);
	border-color: var(--el-button-hover-border-color);
	background-color: var(--el-button-hover-bg-color);
	outline: 0;
}
</style>
