<template>
    
    <pc-header></pc-header>
    <!-- <div style="margin-left: 300px;margin-top: 10px;">
      <router-link to="/">
        <el-image style="width: 160px; height: 80px; margin-top: 20px;margin-left: 10vw;margin-right: 8vw;"
                  :src="logoUrl" fit="fill"/>
      </router-link>
    </div> -->
    <el-card class="resetPassword-container">
      <div style="font-size: 30px;margin: 30px 260px;font-weight: 600;height: 100px;">Reset Password</div>
      <el-form ref="form" :model="form" label-width="120px" :rules="rules" label-position="top" style="font-size: 16px;font-weight: 600;">
	      
	      <el-form-item label="Email Address" prop="userName">
		      <el-input v-model="form.userName" placeholder="Please enter your email address" style="height: 50px;">
			      
		      </el-input>
	      </el-form-item>
	      <el-form-item label="Code" prop="sms">
		      <el-input v-model="form.sms" placeholder="Please enter your received code" style="height: 50px;width: 300px;margin-right: 10px;"></el-input>
          <el-button type="primary" @click="sendEmail" style="width:100px;height: 50px;" :loading="sendEmailLoading" :disabled="!form.userName || sendEmailLoading || countDownNum > 0">
					      {{ countDownNum > 0 ? `${countDownNum}s try again` :  'Send'}}
				      </el-button>
	      </el-form-item>
        <el-form-item label="New Password" prop="password">
          <el-input v-model="form.password" type="password" placeholder="Please enter password" style="height: 50px;"></el-input>
        </el-form-item>
        <el-form-item label="Confirm Password" prop="confirmPassword">
          <el-input v-model="form.confirmPassword" type="password"  placeholder="Please enter your confirm password" style="height: 50px;"></el-input>
        </el-form-item>
        
        
      </el-form>
      
     
      
      <div style="margin: 50px 30px;">
        <span>You can use another account  
          <router-link to="/pages/login">
          <el-link type="danger" style="margin-bottom: 3px;">Login</el-link>
          </router-link>
           here</span>
        <el-button type="primary" style="height: 60px;width: 180px;margin-left: 220px;font-size: 20px;" @click="resetPassword">Reset Password</el-button>
      </div>
    </el-card>

    <pc-footer/>
</template>

<script >
import { ref } from 'vue'
import { Delete } from '@element-plus/icons-vue'
import {getSms, resetPassword} from "@/api/customer/account.js";
import useCustomerStore from "@/store/customer"
import {useRouter, useRoute} from "vue-router"
import {ElMessage} from "element-plus";

export default {
  name: 'ResetPassword',
  components: {
    Delete
  },
  props: {
    msg: String
  },
  data() {
    return {
      form:{
        userName:null,
        sms: null,
        password:null,
        confirmPassword:null,
        type:3,
      },
      logoUrl:require('@/assets/img/logo3.gif'),
      useCustomerStore:useCustomerStore(),
      router:useRouter(),
      rules: {
        userName: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' }
        ],
	      sms: [
		      { required: true, message: 'Please enter your received code', trigger: 'blur'},
	      ],
        password: [
          { required: true, message: 'Please enter password', trigger: 'blur'}
        ],
        confirmPassword: [
          { required: true, message: 'Please enter your confirm password', trigger: 'blur'},
          { // 新增的自定义验证规则
            validator: (rule, value, callback) => {
              if (value !== this.form.password) {
                callback(new Error('The confirmation password must match the password'));
              } else {
                callback();
              }
            },
            trigger: 'blur' // 可以根据需要选择触发时机：'blur'（失去焦点时）或 'change'（值改变时）
          }
        ],
      },
	    countDownNum: 0,
	    countDownTimer: null,
	    sendEmailLoading: false,
    }
  },
  methods: {
    async resetPassword(){
      try {
        await this.$refs['form'].validate();
        await resetPassword(this.form);
	      ElMessage({
		      message: "Successfully reset",
		      type: "success"
	      });
				setTimeout(()  => {
					 this.router.push({path: '/pages/login'})
				}, 3000)
      } catch (err) {
        // 验证失败，err 是包含错误信息的对象
        console.error('表单验证失败：', err);
      }
    },
	  // 发送邮箱验证码
	  async sendEmail() {
		  if (!this.form.userName) return
		  this.sendEmailLoading = true
		  try {
			  await getSms({
				  phone: this.form.userName,
				  accountType: 3,
				  smsType: 4
			  })
			  this.sendEmailLoading = false
			  this.countDown(); // 触发倒计时
			  ElMessage({
				  message: "Successfully send  email",
				  type: "success"
			  });
		  } catch (err){
		  } finally {
			  this.sendEmailLoading = false
		  }
	  },
	  // 验证码倒计时倒计时
	  countDown() {
		  this.countDownNum = 30;
		  this.countDownTimer = setInterval(() => {
			  this.countDownNum = this.countDownNum - 1;
			  if (this.countDownNum <= 0) clearInterval(this.countDownTimer)
		  }, 1000)
	  }
  },
	beforeDestroy() {
		clearInterval(this.countDownTimer)
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.resetPassword-container{
	width: 800px;
	margin: 60px auto;
}
.resetPassword-container :deep(.el-input-group__append button.el-button){
	color: var(--el-button-hover-text-color);
	border-color: var(--el-button-hover-border-color);
	background-color: var(--el-button-bg-color);
}
.resetPassword-container :deep(.el-input-group__append button.el-button:hover) {
	color: var(--el-button-hover-text-color);
	border-color: var(--el-button-hover-border-color);
	background-color: var(--el-button-hover-bg-color);
	outline: 0;
}
</style>
