<template >

  <pc-header></pc-header>
    
    <div style="text-align: center;height: 150px;background: linear-gradient(to right, #c6e2ff, #79bbff);padding-top: 30px;">
      <div style="font-size: 24px;color: #141517;font-weight: 600;margin-bottom: 20px;">Order is currently being confirmed</div>
      <div> 
        <router-link to="/">
          <el-link href="" >Home</el-link> 
        </router-link>
       
        /  
        <span >Order is currently being confirmed</span>
      </div>
    </div>

    <div v-loadding="true" style="height: 100px;margin: 60px 0;">

    </div>

  <pc-footer></pc-footer>
</template>



<script >
import { ref } from 'vue'
import { QuestionFilled } from '@element-plus/icons-vue'
import { amazonpay,paypalpay,googlepay,cardpay,amazongpayUpdate,offlinepay } from "@/api/order/pay.js";
import { order,calculatePrice } from "@/api/order/order.js";
import { queryAll } from "@/api/customer/customerAddress.js";
import { myPoints } from "@/api/customer/customerWallet.js";
import { my } from "@/api/customer/customer.js";
import { myAccount } from "@/api/customer/account.js";
import {useRouter, useRoute} from "vue-router"
import useCartCache from "@/store/cart";
import {getAccessToken} from "@/utils/auth";
// import { StripeGooglePay } from 'vue-stripe-elements-plus';

export default {
  name: 'order',
  components: {
    QuestionFilled,
    // StripeGooglePay
  },
  props: {
    msg: String
  },
  data() {
    return {

      router : useRouter(),
      cartCacheStore : useCartCache(),
      loadding:true,
    }
  },

  async created() {
    let returnUrl = "";
    if(getAccessToken()){
      returnUrl = '/pages/my?type=order';
    }else{
      returnUrl = '/pages/orderQuery';
    }
    if(this.$route.query.orderId){
      const {data:url} = await amazongpayUpdate({orderId:this.$route.query.orderId,returnUrl:window.location.origin + returnUrl});
      window.location.href= url.amazonPayRedirectUrl;
    }else{
      setTimeout(() => {
        this.$router.push({path:returnUrl});
       
      }, 3000);
    }
    
    
  },
  methods: {
    
    
  },
  async mounted() {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .el-popper.is-dark2 {
  	background: #e35959;
    border: 1px solid #e35959;
    margin-left: 10px;
    vertical-align: middle;
    width: 22px;
    height: 12px;
  }
  .el-popper.is-dark2 .el-popper__arrow::before{
    background: #e35959;
    border: 1px solid #e35959;
  }
  .el-popover.el-popover.cc{
    padding: 0;
  }
  .el-tabs__item{
    font-size:24px !important;
    }
    #tab-first{
      margin-bottom: 10px;
    }




    
</style>
