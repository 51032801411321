import {defineStore} from "pinia"
import {ref, reactive, watch} from "vue"
import useCache from "@/utils/useCache";
import {doAddCart, doDeleteCart, getCartAll,doUpdateCart} from "@/api/spu/cart"
import {getProductAllByPage, getSpuAll} from "@/api/spu/index"
import useCustomerStore from "@/store/customer"
import {QueryCondition, queryWrapper} from "@/utils/queryWrapper";
import {getAccessToken} from "@/utils/auth";
import { ElMessage,ElMessageBox  } from 'element-plus'


const useCartCache = defineStore("cart", () => {

  const storage = useCache("localStorage")
  const customerStore = useCustomerStore()
  const cartList = ref([])
  const calcResult = ref({
    totalNumber: 0,
    amtTotal: 0,
    payAmt: 0,
    checkTotal: 0
  })

  const cartDrawer= ref(false);

  // 筛选对应的商品index（通过productSpecId确认唯一）
  const filterProductIndex = (product) => {
    return cartList.value.findIndex((carProduct) => {
      if(carProduct.productSpecId === product.productSpecId){
        carProduct.num += product.num;
        return true;
      }
      // return carProduct.productSpecId === product.productSpecId;
    })
  }

  //初始化购物车
  const initCart = async () => {
    if(getAccessToken()){
      await getCurrentCartList() // 重新查询数据
    } else {
      const cartCache = storage.get("cartList");
      cartList.value = cartCache ? JSON.parse(cartCache) : [];
    }
    console.log("cartList",cartList);
  }

  // 添加商品
  const addCartItem = async (product) => {
    debugger
    const existProductIndex =   filterProductIndex(product);
    if (existProductIndex >= 0) return; // 如果存在直接return

    // 移除id并赋予productId
    const {id, ...rest} = product;
    const cartItem = {...rest, productId: id, productSpecId: product.productSpecId, num: product.num, checked: true};

    // 如果用户数据存在，直接新增数据
    // if (customerStore.customerInfo.id) {
    if(getAccessToken()){
      // await doAddCart({...cartItem});
      await doAddCart({productId: id, productSpecId: product.productSpecId, num: product.num});
      await getCurrentCartList() // 重新查询数据
      // cartList.value.filter(cartItem => {
      //   if(cartItem.productSpecId == product.productSpecId){
      //     cartItem.checked =true;
      //   }
      // })
    } 
    else {
      cartList.value = [...cartList.value, cartItem]
    }
  }

  // 直接移除商品
  const removeCartItem = async (item) => {

    // 如果用户数据存在，直接新增数据
    // if (customerStore.customerInfo.id) {
    if(getAccessToken()){
      await doDeleteCart({id: item.id});
      // await getCurrentCartList() // 重新查询数据
      
    } 
    // else {
      cartList.value = cartList.value.filter(cartItem => {
        return cartItem.productSpecId !== item.productSpecId;
      })
    // }
  }

  const getCurrentCartList = async () => {
    const {data: cartListData = []} = await getCartAll({});

     // 查询spu信息
     if (!cartListData.length){
      cartList.value = []
      return;
     } 
     const productSpecIds = cartListData.map(item => item.productSpecId);
     const {data: spuList} = await getSpuAll({
      conditions: [queryWrapper("id", QueryCondition.IN, productSpecIds)]
    })

    // 查询商品信息
    const productIds = cartListData.map(item => item.productId);
    const {data: {list:productList}} = await getProductAllByPage({
      page: 1,
      pageSize: 100,
      conditions: [queryWrapper("id", QueryCondition.IN, productIds)]
    })
    
   

    // 混合数据
    try{
      cartList.value = cartListData.map(cartItem => {
        if (!cartItem.productSpecId) return null;
        const product = productList.find(item => item.id === cartItem.productId);
        let spu = spuList.find(item => item.id === cartItem.productSpecId);
        
        const {id: productId, ...restProduct} = product;
        const {id: productSpecId, specDesc, ...restSpu} = spu;
         
        
  
        return {
          ...cartItem, ...restProduct, ...restSpu,imgs: product.imgs,
          specDesc: specDesc ? JSON.parse(specDesc) : [],
          checked: true
        }
      })
    }catch(e){
      // ElMessage({
      //   message: e,
      //   type: "error"
      // });
      console.log("error",e)
    }
    
  }


  //  添加购物车商品
  // const increaseCart = (product, num) => {
  //   const existProductIndex = filterProductIndex(product);
  //
  //   if (existProductIndex !== -1) {
  //     cartList.value[existProductIndex].cartNum = Number(cartList.value[existProductIndex].cartNum) + num;
  //   }
  //   console.log("当前购物车商品", cartList.value)
  // }
  //
  // //  减少购物车商品
  // const decreaseCart = (product, num) => {
  //   const existProductIndex = filterProductIndex(product);
  //
  //
  //   let currentProduct = cartList.value[existProductIndex];
  //
  //   // 如果剩余数量只剩余一个。直接删除商品
  //   console.log("当前购物车数量", Number(currentProduct.cartNum));
  //   if (Number(currentProduct.cartNum) === 1) {
  //     cartList.value = cartList.value.filter(item => {
  //       return item.productSpecId !== currentProduct.productSpecId
  //     })
  //   } else {
  //     currentProduct.cartNum = Number(currentProduct.cartNum) - num;
  //   }
  // }

  // 清空购物车
  const clearAll = () => {
    cartList.value = [];
  }

  //缓存购物车数据在本地，方便下进入
  watch( ()=>JSON.parse(JSON.stringify(cartList.value)), (newList, oldList) => {
    // if (!customerStore.customerInfo.id) {
    if(!getAccessToken()){
      storage.set("cartList", JSON.stringify(cartList.value));
    }else{
      //比较新旧两个数组的值，不同则修改
      if(oldList.length>0){
        const needUpdate = newList.filter(x => {
          return oldList.find(y => {
            if(x.id == y.id){
              if(x.num != y.num){
                return true;
              }
              if(x.checked != y.checked){
                return true;
              }
            }
            
          });
        });
        
        if(needUpdate.length>0){
          needUpdate.forEach(async item => {
            await doUpdateCart({id: item.id, num: item.num, checked: item.checked ? 1:0});
          })
        }
      }
      
    }
  }, {deep: true})

  // 下次打开获取缓存
  const getLocalCacheData = () => {
    const cartCache = storage.get("cartList");
    cartList.value = cartCache ? JSON.parse(cartCache) : [];
  }


  // 用于计算金额
  const calcTotal = async (cartItems = []) => {
    

    let totalNumber = 0; // 售卖数量
    let amtTotal = 0; //总金额
    let payAmt = 0; // 应付金额
    let checkTotal = 0; // 购物车选中总价
    const requestData = {}; // 拼装数据

    // 直接返回
    if (cartItems && cartItems.length === 0) {
      return {
        totalNumber,
        amtTotal,
        payAmt,
        checkTotal
      }
    }

    cartItems.forEach(cart => {
      totalNumber = totalNumber + Number(cart.num);
      amtTotal = amtTotal + Number(cart.num) * Number(cart.oldPrice)
      payAmt = payAmt + Number(cart.num) * Number(cart.price)

      if (cart.checked) checkTotal = checkTotal + Number(cart.num) * Number(cart.price)

      // requestData.orderProductReqDto.push({
      //   productId: cart.id,
      //   productName: cart.name,
      //   price: cart.price,
      //   saleNum: Number(cart.num)
      // })
    })

    //请求后端获取的数据
    // const {data} = await getOrderPrice(requestData);
    // console.log("计算返回的价格", {...data, totalNumber});
    return {
      totalNumber,
      amtTotal,
      payAmt,
      checkTotal
    }
  }

  // 计算总数
  watch(cartList, async () => {
    calcResult.value = await calcTotal(cartList.value);
    console.log("计算结果触发", calcResult.value)

    
  }, {deep: true, immediate: true})


  return {cartList, calcResult, addCartItem, removeCartItem, calcTotal, clearAll, getLocalCacheData, cartDrawer,initCart}
})


export default useCartCache
