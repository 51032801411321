import request from "@/utils/request/index"


// 获取商品信息
export const getProductAllByPage = (data) => {
  return request({
    url: "/openapi/product/getAllByPage",
    data
  })
}

// 获取商品信息
export const getProductById = (data) => {
  return request({
    url: "/openapi/product/getById",
    data
  })
}

// 获取商品对应的spu
export const getSpuAll = (data) => {
  return request({
    url: "/openapi/productSpec/getAll",
    data
  })
}

// 获取商品分类
export const getCategoryList = (data) => {
  return request({
    url: "/openapi/productCategory/getAllByPage",
    data
  })
}

// 获取年龄选项(标签)
export const getAgeList = (data) => {
  return request({
    url: "/openapi/label/queryAll",
    data
  })
}

// 获取所有的标签商品（带条件不分页）
export const getProductLabelAll = (data) => {
  return request({
    url: "/openapi/productLabel/getAll",
    data
  })
}

