import request from "@/utils/request/index"


// 亚马逊支付
export const myPoints = (data) => {
  return request({
    url: "/openapi/customerWallet/myPoints",
    data
  })
}




