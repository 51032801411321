<template>
  <div style="background: #212529;">
   <div  class="pc-footer" style="vertical-align: middle;color: #fff;margin:auto;width: 75vw;min-width: 1300px;">
        
        <div style="border-bottom: 1px solid rgba(255,255,255,0.14);display: flex;height: 110px;">
            <div style="margin-top:40px;margin-left:3vw">
              <el-text style="font-size: 18px;color: #fff;margin-right: 7vw;">Free Shipping on orders over $200</el-text>
            </div>
            
            <div style="margin-top:30px">
              <el-input style="width: 400px;height: 50px;margin-right: 10px;"  v-model="form.phone" placeholder="Please input your email" />
              <el-button type="primary" style="margin-right: 7vw;height: 50px;width: 100px;" @click="inquiryDialog = true">SUBMIT</el-button>
            </div>
            
            <div style="margin-top:35px">
              <el-link :href="twitterUrl" target="_blank"  :underline="false">
                <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="twitterImg"/>
              </el-link>
              <el-link :href="facebookUrl" target="_blank" :underline="false">
                <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="facebookImg"/>
              </el-link>
              <el-link :href="instagramUrl" target="_blank"  :underline="false">
                <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="instagramImg"/>
              </el-link>
            </div>
            
            <el-link :href="emailUrl" :underline="false">
              <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="emailImg"/>
            </el-link>
            <!-- <el-link :href="googleUrl" target="_blank"  :underline="false">
              <el-image style="width: 40px; height: 40px;margin-right: 10px;" :src="googleImg"/>
            </el-link> -->

        </div>
        
        
        
        <div style="height: 300px;border-bottom: 1px solid rgba(255,255,255,0.14);height: 300px;padding:30px 3vw;display: flex;">
          <div style=" margin-right: 5vw;">
            <el-image style="width: 200px; height: 80px;" :src="logoUrl"/>
          </div>
          
          
          <div style="margin-right: 5vw;">
            <div style="font-size: 24px;margin-bottom: 20px;">Quick links</div>

            <div>
              <router-link to="/pages/productList">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Shop by Category</el-link>
              </router-link>
            </div>

            <div>
              <router-link to="/pages/productList">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Shop by Age</el-link>
              </router-link>
            </div>

            <div>
              <router-link to="/pages/login">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Log in</el-link>
              </router-link>
            </div>
            <div>
              <router-link to="/pages/clearance">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Today's Deals</el-link>
              </router-link>
            </div>
            <div>
              <router-link to="/pages/compare">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Compare</el-link>
              </router-link>
            </div>
          </div>
          <div style="margin-right: 5vw;">
            <div style="font-size: 24px;margin-bottom: 20px;">ABOUT US</div>
            <div>
              <router-link to="/pages/support?type=Q&A">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">FAQ</el-link>
              </router-link>
            </div>
          
            
            <div>
              <router-link to="/pages/support?type=About Us">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">About Adena</el-link>
              </router-link>
              
            </div>
            
            <div>
              <router-link to="/pages/support?type=Price List">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Price List</el-link>
              </router-link>
            </div>

            <div>
              <router-link to="/pages/support?type=Order Form">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Order Form</el-link>
              </router-link>
            </div>

          </div>
         
          <div style="margin-right: 5vw;">
            <div style="font-size: 24px;margin-bottom: 20px;">WHY BUY FROM US</div>
            
            <div>
              <router-link to="/pages/support?type=Security & Privacy">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Security & Privacy</el-link>
              </router-link>
              
            </div>

            <div>
              <router-link to="/pages/support?type=Return & Exchanges Policy">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Return & Exchange</el-link>
              </router-link>
              
            </div>
            
            <div>
              <router-link to="/pages/support?type=Cancellation and Change">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Cancellation and Change</el-link>
              </router-link>
              
            </div>

            <div>
              <router-link to="/pages/support?type=Lead Test Report">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Lead Test Report</el-link>
              </router-link>
              
            </div>
            
            <div>
              <router-link to="/pages/orderQuery">
                <el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Track My Order</el-link>
              </router-link>
            </div>
            
            
          </div>
          <div style="">
            <div style="font-size: 24px;margin-bottom: 20px;">CONTACT US</div>
            <div><el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Email service@adenamontessori.com</el-link></div>
            <div><el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Fax 1.866.856.1148</el-link></div>
            <div><el-link :underline="false" style="font-size: 14px;color: #cfcdcd;margin-top: 10px;">Phone 888-805-6919</el-link></div>
          </div>
        </div>
        
        <!-- <hr/> -->
          
        <div style="margin-top: 60px;width: 600px;margin: auto;padding: 30px 0px;">
          Copyright © 2023-2024 Adena Montessori All rights reserved.
          <!-- <el-image :src="bbburl" /> -->
          <!-- <el-image :src="paypalImg" style="width: 60px;height: 30px;margin-right: 10px;"/>
          <el-image :src="amazonImg" style="width: 60px;height: 30px;margin-right: 10px;"/>
          <el-image :src="gpayImg" style="width: 60px;height: 30px;margin-right: 10px;"/> -->
        </div>
      </div>
    </div>
  
    
    <!-- 询盘 -->
  <el-dialog v-model="inquiryDialog" title="We will contact you as soon as possible" width="600">
    <el-form :model="form" label-width="100px" size="large">
      <el-form-item label="email"  prop="phone" >
        <el-input style="width: 400px;height: 50px;margin-right: 20px;"  v-model="form.phone" placeholder="Please input your email" />
      </el-form-item>
      <el-form-item label="First Name"  prop="email" >
        <el-input style="width: 400px;height: 50px;margin-right: 20px;"  v-model="form.firstName" placeholder="Please input your first name" />
      </el-form-item>
      <el-form-item label="Last Name"  prop="email" >
        <el-input style="width: 400px;height: 50px;margin-right: 20px;"  v-model="form.lastName" placeholder="Please input your last name" />
      </el-form-item>
      <el-form-item label="Content"  prop="content" >
        <el-input type="textarea" style="width: 400px;height: 50px;margin-right: 20px;"  v-model="form.content" placeholder="Please input content" />
      </el-form-item>
      
      
    </el-form>
    
    
    <template #footer>
      <div class="dialog-footer">
        <el-button @click="inquiryDialog = false">Cancel</el-button>
        <el-button type="primary" @click="saveInquiry">
          Confirm
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script >
import { ref } from 'vue'
import { Search } from '@element-plus/icons-vue'
import { create } from '@/api/inquiry'
import { ElMessage,ElMessageBox  } from 'element-plus'

export default {
  name: 'pcfooter',
  props: {
    msg: String
  },
  data() {
    return {
      logoUrl: require("@/assets/img/logo.png"),
      twitterImg: require("@/assets/img/Twitter.png"),
      facebookImg: require("@/assets/img/facebook.png"),
      instagramImg: require("@/assets/img/instagram.png"),
      emailImg: require("@/assets/img/email.png"),
      googleImg: require("@/assets/img/google.png"),
      bbbUrl: require("@/assets/img/bbb.png"),
      // gpayImg: require('@/assets/img/GPay.png'),
      // paypalImg: require('@/assets/img/PayPal.png'),
      // amazonImg: require('@/assets/img/amazon.png'),
      email:null,
      inquiryDialog: false,
      form:{
        firstName: null,
        lastName: null,
        phone: null,
        content: null
      },
      twitterUrl:"",
      facebookUrl:"",
      instagramUrl:"https://www.instagram.com/adenamontessori",
      emailUrl:"",
      googleUrl:"",
      bbbUrl:"",
      emailUrl:"",
    
    }
  },
  created() {
    this.getTwitterUrl();
    this.getfacebookUrl();
    this.googleUrl = "https://www.google.com/bookmarks/mark?op=edit&output=popup&bkmk=" + encodeURIComponent(window.location.href);
    this.emailUrl = "mailto:subject=Adena Montessori&body=I shared a connection with you: "+ encodeURIComponent(window.location.href)
  },
  methods: {
    async saveInquiry(){
      await create(this.form);
      this.inquiryDialog = false
      ElMessage({
          message: "Submit success!",
          type: "success"
      });
    },
    getTwitterUrl(){
      let url = encodeURIComponent(window.location.href);
      let text = encodeURIComponent('View the page I am browsing'); // 替换为实际要分享的文字内容
      let via = 'adenamontessori'; // 替换为你的Twitter用户名
      this.twitterUrl = "https://twitter.com/intent/tweet?text=" + text + "&url=" + url + "&via=" + via;
      
    },
    getfacebookUrl(){
      let u = encodeURIComponent(window.location.href);
      let t = encodeURIComponent('View the page I am browsing');
      this.facebookUrl = "https://www.facebook.com/sharer.php?u="+ u+"&t="+t;
      
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
  .pc-footer {
	  position: static;
	  bottom: 0;
  }
</style>
