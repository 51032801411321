import useCache from '@/utils/useCache'


const wsCache = useCache('localStorage')

const AccessTokenKey = 'ACCESS_TOKEN'
export const AccessTokenName = 'token'


// ==================== TOKEN 相关 =====================

// 获取token
export const getAccessToken = () => {
  return wsCache.get(AccessTokenKey)
}

// 设置token
export const setToken = (token) => {
  wsCache.set(AccessTokenKey, token)
}

// 删除token
export const removeToken = () => {
  wsCache.remove(AccessTokenKey)
}

/** 注入token 普通模式 */
export const injectTokenNormal = (config) => {
  config.headers[AccessTokenName] = getAccessToken()
  return config
}

/** 注入token jwt格式 */
export const injectTokenJWT = (config, token) => {
  config.headers['Authorization'] = 'Bearer ' + token
  return config
}


// ========== 租户相关 ==========

const TenantIdKey = "AppKey";

// 注入商户Id
export const injectTenantId = (config) => {
  config.headers[TenantIdKey] = process.env.VUE_APP_APP_KEY
  return config
}
